import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToPayload } from '@sap/logic/shared/interfaces';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { Role } from '@sap/logic/shared/models/role';
import { User } from '@sap/logic/shared/models/user';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { catchError, lastValueFrom, map } from 'rxjs';
import { ApiConfig } from '../api-config';
import { ApiForbiddenDetector, MethodError } from '../detectors/api-forbidden-detector';
import { MessageError } from '../errors/message-error';

@Injectable({
  providedIn: 'root'
})
export class AuthApi {
  constructor(
    private _sharedSettings: SharedSettings,
    private _http: HttpClient,
    private _apiConfig: ApiConfig,
    private _apiForbiddenDetector: ApiForbiddenDetector
  ) {}

  public auth(payload: ToPayload): Promise<AuthToken | MessageError> {
    return lastValueFrom(
      this._http
        .post(`${this._sharedSettings.environment.authApiUrl}auth/v1/authenticate/`, payload.toPayload())
        .pipe(map(AuthToken.fromJson), MessageError.fromHttpError())
    );
  }

  public authRefresh(): Promise<AuthToken | MessageError> {
    return lastValueFrom(
      this._http
        .post(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/authenticate/refresh/`,
          {},
          {
            headers: this._apiConfig.authRefreshHeaders
          }
        )
        .pipe(map(AuthToken.fromJson), MessageError.fromHttpError())
    );
  }

  public getRoles(): Promise<Role[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/roles/`,
          this._apiConfig.authOptions
        )
        .pipe(map(Role.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public getRole(id: number): Promise<Role> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/roles/${id}`,
          this._apiConfig.authOptions
        )
        .pipe(map(Role.fromJson))
    );
  }

  public deleteRole(id: number): Promise<null | MessageError> {
    return lastValueFrom(
      this._http
        .delete(`${this._sharedSettings.environment.authApiUrl}auth/v1/roles/${id}`, this._apiConfig.authOptions)
        .pipe(
          map(() => null),
          MessageError.fromHttpError()
        )
    );
  }

  public createRole(form: Record<string, any>): Promise<Role | MessageError> {
    return lastValueFrom(
      this._http
        .post<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/roles/`,
          form,
          this._apiConfig.authOptions
        )
        .pipe(
          map(Role.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public updateRole(form: Record<string, any>, roleId: number): Promise<Role | MessageError> {
    return lastValueFrom(
      this._http
        .put<Record<string, any>[]>(`${this._sharedSettings.environment.authApiUrl}auth/v1/roles/`, form, {
          params: { ['role_id']: roleId },
          headers: this._apiConfig.authHeaders
        })
        .pipe(map(Role.fromJson), MessageError.fromHttpError())
    );
  }

  public getUsers(): Promise<User[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/`,
          this._apiConfig.authOptions
        )
        .pipe(map(User.fromJsonArray), catchError(this._apiForbiddenDetector.detectOnHttpError()))
    );
  }

  public getUser(id: number): Promise<User | MessageError> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/${id}`,
          this._apiConfig.authOptions
        )
        .pipe(
          map(User.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError()),
          MessageError.fromHttpError()
        )
    );
  }

  public deleteUser(id: number): Promise<null | MessageError> {
    return lastValueFrom(
      this._http
        .delete(`${this._sharedSettings.environment.authApiUrl}auth/v1/users/${id}`, this._apiConfig.authOptions)
        .pipe(
          map(() => null),
          MessageError.fromHttpError(),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write))
        )
    );
  }

  public createUser(form: Record<string, any>): Promise<User | MessageError> {
    return lastValueFrom(
      this._http
        .post<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/`,
          form,
          this._apiConfig.authOptions
        )
        .pipe(
          map(User.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public updateUser(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    return lastValueFrom(
      this._http
        .put<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/${userId}`,
          form,
          this._apiConfig.authOptions
        )
        .pipe(
          map(User.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public changePassword(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    return lastValueFrom(
      this._http
        .put<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/${userId}/change-password`,
          form,
          this._apiConfig.authOptions
        )
        .pipe(
          map(User.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }

  public resetPassword(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    const { targetUserId, ...newPasswords }: Record<string, any> = form;
    return lastValueFrom(
      this._http
        .put<Record<string, any>[]>(
          `${this._sharedSettings.environment.authApiUrl}auth/v1/users/${userId}/reset-password/${targetUserId}`,
          newPasswords,
          this._apiConfig.authOptions
        )
        .pipe(
          map(User.fromJson),
          catchError(this._apiForbiddenDetector.detectOnHttpError(MethodError.write)),
          MessageError.fromHttpError()
        )
    );
  }
}
