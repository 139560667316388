import { Injectable } from '@angular/core';
import { InfoRepositoryCached } from '@sap/logic/api-access/repository-cached/info-repository-cached';
import { mapToBrandTypes } from '@sap/shared-settings/pure-utils';
import { BrandType } from '@sap/shared/enums';
import * as R from 'rambdax';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { mapToAllCountries } from '../filters/pure-utils';
import { BrandInfo, Country } from '../models/brand-info';

export const unassignedBrandOption: BrandType = 'Unassigned' as BrandType;

@Injectable({ providedIn: 'root' })
export class SharedBrand {
  constructor(private _infoRepositoryCached: InfoRepositoryCached) {}

  public getBrandsWithUnassigned(): Observable<BrandType[]> {
    return this.getBrands().pipe(map((brands: BrandType[]) => brands.concat(unassignedBrandOption)));
  }

  public getBrandsWithoutDefault(): Observable<BrandType[]> {
    return this.getBrands().pipe(
      map((brands: BrandType[]) => R.filter((brand: BrandType) => brand !== BrandType.default, brands))
    );
  }

  public hasAccessToDefaultBrand(): Observable<boolean> {
    return this.getBrands().pipe(map(R.includes('default')));
  }

  public getBrands(): Observable<BrandType[]> {
    return this._infoRepositoryCached.getBrandsInfo().pipe(map(mapToBrandTypes));
  }

  public getBrandsInfo(): Observable<BrandInfo[]> {
    return this._infoRepositoryCached.getBrandsInfo();
  }

  public getBrandsInfoWithoutDefault(): Observable<BrandInfo[]> {
    return this._infoRepositoryCached
      .getBrandsInfo()
      .pipe(map((brands: BrandInfo[]) => R.filter((brand: BrandInfo) => brand.name !== BrandType.default, brands)));
  }

  public getInitialBrand(skipBrand?: BrandType, brandFromLocalStorage?: BrandType): Observable<BrandType> {
    return this.getBrands().pipe(
      filter(Boolean),
      map((brands: BrandType[]) => {
        if (skipBrand) {
          brands = R.filter((brand: BrandType) => brand !== skipBrand, brands);
        }
        const initialBrand: BrandType =
          (brandFromLocalStorage && brands.includes(brandFromLocalStorage)) || brands.includes(BrandType.default)
            ? brandFromLocalStorage || BrandType.default
            : brands.at(0)!;
        return initialBrand;
      }),
      take(1)
    );
  }

  public getAllBrandsCountries(): Observable<Country[]> {
    return this._infoRepositoryCached.getBrandsInfo().pipe(map(mapToAllCountries), map(R.uniq));
  }
}
