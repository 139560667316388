import { Injectable } from '@angular/core';
import { ToPayload } from '@sap/logic/shared/interfaces';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { Role } from '@sap/logic/shared/models/role';
import { User } from '@sap/logic/shared/models/user';
import { AuthApi } from '../api/auth-api';
import { MessageError } from '../errors/message-error';

@Injectable({
  providedIn: 'root'
})
export class AuthRepository {
  constructor(private _authApi: AuthApi) {}

  public auth(payload: ToPayload): Promise<AuthToken | MessageError> {
    return this._authApi.auth(payload);
  }

  public authRefresh(): Promise<AuthToken | MessageError> {
    return this._authApi.authRefresh();
  }

  public getRoles(): Promise<Role[]> {
    return this._authApi.getRoles();
  }

  public getRole(id: number): Promise<Role> {
    return this._authApi.getRole(id);
  }

  public deleteRole(id: number): Promise<null | MessageError> {
    return this._authApi.deleteRole(id);
  }

  public createRole(form: Record<string, any>): Promise<Role | MessageError> {
    return this._authApi.createRole(form);
  }

  public updateRole(form: Record<string, any>, roleId: number): Promise<Role | MessageError> {
    return this._authApi.updateRole(form, roleId);
  }

  public getUsers(): Promise<User[]> {
    return this._authApi.getUsers();
  }

  public getUser(id: number): Promise<User | MessageError> {
    return this._authApi.getUser(id);
  }

  public deleteUser(id: number): Promise<null | MessageError> {
    return this._authApi.deleteUser(id);
  }

  public createUser(form: Record<string, any>): Promise<User | MessageError> {
    return this._authApi.createUser(form);
  }

  public updateUser(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    return this._authApi.updateUser(form, userId);
  }

  public changePassword(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    return this._authApi.changePassword(form, userId);
  }

  public resetPassword(form: Record<string, any>, userId: number): Promise<User | MessageError> {
    return this._authApi.resetPassword(form, userId);
  }
}
