import { Filters } from '@sap/logic/api-access/api/match-api';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { mapToSportsGroupsWithoutUnassigned } from '@sap/logic/shared/pure-utils/to-sports-groups-without-unassigned';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { BrandType, BrandTypeWithoutDots } from '@sap/shared/enums';
import * as R from 'rambdax';

export function mapToSelectedBrands([[index, brand], brands, defaultCount, selectedBrandsFromLS]: [
  [number, BrandType] | never[],
  BrandType[],
  number,
  BrandType[] | undefined
]): BrandType[] {
  let selectedBrands: BrandType[] = brands;
  if (selectedBrandsFromLS && selectedBrandsFromLS.length < defaultCount) {
    selectedBrands = brands;
  } else if (selectedBrandsFromLS) {
    selectedBrands = selectedBrandsFromLS;
  }
  if (isDefined(index)) {
    return R.adjust(index, () => brand, selectedBrands);
  }
  return selectedBrands;
}

export function mapToFilteredSportsGroupsByBrandVisibility([sportsGroups, filters]: [
  SportsGroup[],
  Filters
]): SportsGroup[] {
  if (filters.unassignedBrands) {
    return sportsGroups;
  }
  return mapToSportsGroupsWithoutUnassigned(sportsGroups);
}

export function mapToUpdateRestrictionChanges(
  editedSportGroup: SportsGroup,
  brand: BrandTypeWithoutDots,
  updatedRestriction: SportsGroup
): SportsGroup {
  return {
    ...editedSportGroup,
    restriction: {
      ...editedSportGroup.restriction!,
      [brand]: updatedRestriction.restriction![brand]
    }
  };
}
