import { BrandInfo, Country } from '@sap/logic/shared/models/brand-info';
import * as R from 'rambdax';
import { SportsGroup } from '../models/sports-group';

export function mapToAllCountries(brandsInfo: BrandInfo[]): Country[] {
  return R.piped(
    brandsInfo,
    R.reduce((acc: Country[], current: BrandInfo) => {
      acc = acc.concat(current.countries);
      return acc;
    }, [])
  );
}

export function mapToSportGroupsWithUnassignedBrandsFilter([
  sportsGroups,
  disciplinesIdsWithUnassigned,
  filterByUnassignedBrands
]: [SportsGroup[], number[], boolean]): SportsGroup[] {
  if (!filterByUnassignedBrands) {
    sportsGroups = sportsGroups.filter(
      (sportsGroup: SportsGroup) => !disciplinesIdsWithUnassigned.includes(sportsGroup.id)
    );
  }
  return sportsGroups;
}
