import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Theme } from '@sap/ui/shared/services/set-theme.service';

@Component({
  selector: 'sap-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FooterComponent implements OnInit {
  public Theme: typeof Theme = Theme;
  private _selectedTheme: Theme = Theme.dark;
  private _displayOnlyFavoriteSports: boolean = false;
  private _isMenuExpanded: boolean = false;
  private _isFiltersExpanded: boolean = true;

  @Input() userEmail: string | undefined;
  @Input() set displayOnlyFavoriteSports(value: boolean) {
    this._displayOnlyFavoriteSports = value;
  }
  public get displayOnlyFavoriteSportsActivated(): boolean {
    return this._displayOnlyFavoriteSports;
  }
  @Input() set selectedTheme(value: Theme) {
    this._selectedTheme = value;
  }
  public get isDarkTheme(): boolean {
    return this._selectedTheme === Theme.dark;
  }

  @Input() set isMenuExpanded(value: boolean) {
    this._isMenuExpanded = value;
  }
  public get isMenuExpanded(): boolean {
    return this._isMenuExpanded;
  }
  @Input() set isFiltersExpanded(value: boolean) {
    this._isFiltersExpanded = value;
  }
  public get isFiltersExpanded(): boolean {
    return this._isFiltersExpanded;
  }

  @Output() doToggleTheme: EventEmitter<Theme> = new EventEmitter<Theme>();
  @Output() doToggleFavoriteSportsVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this._cdRef.detectChanges();
  }
}
