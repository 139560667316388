import { Injectable } from '@angular/core';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { LocalStorageKeys } from '@sap/shared/enums';
import { LocalStorageService } from '@sap/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenStorage {
  constructor(private _localStorage: LocalStorageService) {}

  public saveAuthToken(authToken: AuthToken): void {
    this._localStorage.setJson(LocalStorageKeys.authToken, authToken.toPayload());
  }

  public clearAuthToken(): void {
    this._localStorage.remove(LocalStorageKeys.authToken);
  }

  public getAuthToken(): AuthToken | undefined {
    const authTokenRaw: Record<string, any> | undefined = this._localStorage.getJson<Record<string, any>>(
      LocalStorageKeys.authToken
    );
    if (authTokenRaw) {
      return AuthToken.fromPayload(authTokenRaw);
    }
    return undefined;
  }
}
