import { MessageError } from '@sap/logic/api-access/errors/message-error';
import * as R from 'rambdax';
import { MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map, scan } from 'rxjs/operators';
import { equalsSimple } from '../utils/pure-utils';

export const scanMergeObjWithAddOrClear: OperatorFunction<any, any> = scan(
  (acc: Record<string, any>, addOrClearFn: (acc: Record<string, any>) => Record<string, any>) => addOrClearFn(acc),
  {}
);

export const scanMergeDeepObj: OperatorFunction<Record<string, any>, Record<string, any>> = scan(
  (acc: Record<string, any>, current: Record<string, any>) => R.mergeDeepRight(acc, current),
  {}
);

export const accAddDeepObj: <T>(current: T) => (acc: Record<string, any>) => Record<string, T> =
  <T>(current: T) =>
  (acc: Record<string, any>): Record<string, T> =>
    R.mergeDeepRight(acc, <object>current) as Record<string, T>;

export const accAddObj: <T>(current: T) => (acc: Record<string, any>) => Record<string, T> =
  <T>(current: T) =>
  (acc: Record<string, any>): Record<string, T> =>
    R.merge(acc, <object>current) as Record<string, T>;

export const clearAccObj: <T>() => (acc: Record<string, any>) => Record<string, T> =
  <T>() =>
  (): Record<string, T> =>
    ({}) as Record<string, T>;

export const scanMergeObjWithAddClear: OperatorFunction<
  ((acc: Record<string, any>) => Record<string, any>) | ((acc: Record<string, any>) => Record<string, any>),
  Record<string, any>
> = scan(
  (acc: Record<string, any>, addOrClearFn: (acc: Record<string, any>) => Record<string, any>) => addOrClearFn(acc),
  {}
);

export const accAddArray: <T>(current: T) => (acc: T) => T =
  <T>(current: T) =>
  (acc: T): T =>
    R.concat(<[]>acc, <[]>current) as T;

export const scanArrayWithAddClear = <T>(): OperatorFunction<((acc: T) => T) | ((acc: T) => T), T> =>
  scan((acc: T, addOrClearFn: (acc: T) => T) => addOrClearFn(acc), [] as T);

export const scanMergeObj: OperatorFunction<Record<string, any>, Record<string, any>> = scan(
  (acc: Record<string, any>, current: Record<string, any>) => R.merge(acc, current),
  {}
);

export const mapTupleToObj = <T extends string | number, V>(): OperatorFunction<
  [key: T, value: V],
  {
    [x: string]: V;
  }
> => map((tuple: [key: T, value: V]) => ({ [tuple[0]]: tuple[1] }));

export const mapAs = <V>(): OperatorFunction<any, V> => map((input: any) => input as V);
export const mapFirstFromArray = <T>(): OperatorFunction<T[], T> => map((arr: T[]) => arr?.[0]);
export const findItemByIdFromArray = <T extends { id: unknown }>(id: string): OperatorFunction<T[], T> =>
  map((array: T[]) => <T>R.find((item: T) => item.id === id, array));

export const distinctUntilArrayLengthChanged = <T>(): MonoTypeOperatorFunction<T[]> =>
  distinctUntilChanged((previous: T[], current: T[]) => equalsSimple(R.length(previous), R.length(current)));

export const scanSpreadArray = <T>(): OperatorFunction<T, T[]> => scan((acc: T[], current: T) => [...acc, current], []);

export const distinctUntilEmittedValuesChanged = <T>(): MonoTypeOperatorFunction<T> =>
  distinctUntilChanged((previous: T, current: T) => R.equals(previous, current));

export const mapSourceWithEditedRecord = <T extends { id: unknown }>(): OperatorFunction<[T[], T[]], T[]> =>
  map(([source, edited]: [T[], Record<keyof T, unknown>[]]) => {
    let copyOfSource: T[] = source.slice();
    for (const editedT of edited) {
      const index: number = copyOfSource.findIndex(R.propEq('id', editedT?.id));
      if (index !== -1) {
        copyOfSource = <T[]>R.adjust(index, () => editedT, copyOfSource);
      }
    }
    return copyOfSource;
  });

export const mapSourceWithRemovedRecord = <T>(): OperatorFunction<[T[], (string | number)[]], T[]> =>
  map(([data, removedIds]: [T[], (string | number)[]]) => {
    let toReturn: T[] = data;
    if (toReturn.length && R.has('id', toReturn[0])) {
      toReturn = toReturn.filter((record: T) => !removedIds.includes(record['id']));
    }
    return toReturn;
  });

export const mapErrorMessageToUndefined = <T>(): OperatorFunction<T | MessageError, T | undefined> =>
  map((response: T | MessageError) => {
    if (response instanceof MessageError) {
      return undefined;
    }
    return response;
  });
