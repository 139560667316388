<ng-container *templateVarTyped="let vars = vars; let update = updateVar">
  <ng-container
    fxThemeCss
    *ngIf="{
      brandsFromScopes: userSettings?.brandsFromScopes ?? [],
      permissions: userSettings?.permissions,
      isMenuExpanded: userSettings?.isMenuExpanded ?? false,
      isFiltersExpanded: userSettings?.isFiltersExpanded ?? false,
      expandedSubMenus: userSettings?.expandedSubMenus ?? {}
    } as userDataSettings"
  >
    <sap-toast></sap-toast>
    <sap-top-navigation
      [isFiltersExpanded]="userDataSettings.isFiltersExpanded"
      (doToggleFiltersSidebar)="doToggleFilters$.next($event)"
    ></sap-top-navigation>
    <div class="with-sidebar">
      <sap-left-navigation-drawer
        class="sidebar-wrapper"
        [nextBetTracerTab]="navigationItems | countBetTracerTabs"
        [brandsFromScopes]="userDataSettings.brandsFromScopes"
        [userPermissions]="userDataSettings.permissions"
        [isExpanded]="userDataSettings.isMenuExpanded"
        [expandedSubMenus]="userDataSettings.expandedSubMenus"
        [currentUrl]="currentUrl"
        (doToggleMenu)="doToggleMenu$.next($event)"
        (doExpandSubMenu)="doExpandSubMenu$.next($event)"
        (doLogout)="doLogout$.next($event)"
      ></sap-left-navigation-drawer>
      <main
        class="container-fluid"
        [class.container-fluid_left-expanded]="userDataSettings.isMenuExpanded"
        [class.container-fluid_right-expanded]="vars.isFiltersActivated ? userDataSettings.isFiltersExpanded : false"
        storeScroll
        [currentUrl]="currentUrl"
      >
        <router-outlet></router-outlet>
      </main>
      <sap-right-filters-drawer
        class="sidebar-wrapper sidebar-right"
        [isExpanded]="userDataSettings.isFiltersExpanded"
        [hidden]="!vars.isFiltersActivated"
      >
        <router-outlet
          name="right-filters"
          (activate)="update('isFiltersActivated', true)"
          (deactivate)="update('isFiltersActivated', false)"
        ></router-outlet>
      </sap-right-filters-drawer>
    </div>
    <sap-footer
      [userEmail]="userEmail"
      [selectedTheme]="selectedTheme"
      [isMenuExpanded]="userDataSettings.isMenuExpanded"
      [isFiltersExpanded]="vars.isFiltersActivated ? userDataSettings.isFiltersExpanded : false"
      (doToggleTheme)="doToggleTheme$.next($event)"
    ></sap-footer>
  </ng-container>
</ng-container>
