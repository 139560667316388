export class UserInfo {
  constructor(
    public id: number,
    public readonly email: string,
    public readonly roles: string[]
  ) {}

  public static fromJson(json: Record<string, any>): UserInfo {
    return new UserInfo(json['claims']['user_id'], json['uid'], json['claims']['roles']);
  }
}
