import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import * as R from 'rambdax';
import { SportsGroupsNamesIndexes } from './types';

export function reduceSportGroupsToDisciplinesRegionsAndLeagues(sportsGroups: SportsGroup[]): {
  disciplines: SportsGroup[];
  regions: SportsGroup[];
  leagues: SportsGroup[];
} {
  const reducedSportsGroups: {
    disciplines: SportsGroup[];
    notDisciplines: SportsGroup[];
  } = R.piped(
    sportsGroups,
    R.reduce(
      (acc: { disciplines: SportsGroup[]; notDisciplines: SportsGroup[] }, value: SportsGroup) => {
        !value.parentId ? acc.disciplines.push(value) : acc.notDisciplines.push(value);
        return acc;
      },
      { disciplines: [], notDisciplines: [] }
    )
  );
  const disciplinesIds: number[] = reducedSportsGroups.disciplines.map(R.prop('id'));
  const reducedSportGroupToRegionsAndLeagues: {
    regions: SportsGroup[];
    leagues: SportsGroup[];
  } = R.piped(
    reducedSportsGroups.notDisciplines,
    R.reduce(
      (acc: { regions: SportsGroup[]; leagues: SportsGroup[] }, value: SportsGroup) => {
        disciplinesIds.includes(value.parentId!) ? acc.regions.push(value) : acc.leagues.push(value);
        return acc;
      },
      { regions: [], leagues: [] }
    )
  );

  return {
    disciplines: reducedSportsGroups.disciplines,
    regions: reducedSportGroupToRegionsAndLeagues.regions,
    leagues: reducedSportGroupToRegionsAndLeagues.leagues
  };
}

export function indexesBySportGroupId(sportsGroups: SportsGroup[]): Record<number, number> {
  const indexesBySportGroupId: Record<number, number> = {};
  for (const [index, sportGroup] of sportsGroups.entries()) {
    indexesBySportGroupId[sportGroup.id] = index;
  }
  return indexesBySportGroupId;
}

export function mapToSportsGroupsNamesIndexes(sportsGroups: {
  disciplines: SportsGroup[];
  regions: SportsGroup[];
  leagues: SportsGroup[];
}): SportsGroupsNamesIndexes {
  const parents: SportsGroup[] = [...sportsGroups.regions, ...sportsGroups.disciplines];
  const indexesParents: Record<number, number> = indexesBySportGroupId(parents);
  const toReturn: SportsGroupsNamesIndexes = {};

  for (const league of sportsGroups.leagues) {
    if (parents[indexesParents[league.parentId!]]) {
      const { name, parentId }: { name: string; parentId: number | null } | undefined =
        parents[indexesParents[league.parentId!]];
      const sportName: string = parents[indexesParents[parentId!]]?.name;

      toReturn[league.id] = {
        sportName,
        regionName: name,
        leagueName: league.name,
        combinedNames: [sportName, name, league.name].filter(Boolean).join(' - ')
      };
    }
  }

  for (const region of sportsGroups.regions) {
    if (parents[indexesParents[region.parentId!]]) {
      const { name }: { name: string; parentId: number | null } | undefined = parents[indexesParents[region.parentId!]];

      toReturn[region.id] = {
        sportName: name,
        regionName: region.name,
        leagueName: undefined,
        combinedNames: [name, region.name].filter(Boolean).join(' - ')
      };
    }
  }

  for (const discipline of sportsGroups.disciplines) {
    toReturn[discipline.id] = {
      sportName: discipline.name,
      regionName: undefined,
      leagueName: undefined,
      combinedNames: discipline.name
    };
  }

  return toReturn;
}
