import { NavigationItem } from '../left-navigation-drawer.component';
import { betTracerMain } from './bet-tracer';
import { allContentChildren, contentMain } from './content';
import { allCrmChildren, crmMain } from './crm';
import { customerSearchMain } from './customer-search';
import { allExternalProductsChildren, externalProductsMain } from './external-products';
import { matchesListMain } from './matches-list';
import { allOfferManagementChildren, offerManagementMain } from './offer-management';
import {
  otherCustomerDetails,
  otherEventView,
  otherUsersCreateRole,
  otherUsersEditRole,
  otherUsersEditUser,
  otherUsersResetPassword,
  otherUsersViewRole
} from './other';
import { allRiskManagementChildren, riskManagementMain } from './risk-management';
import { allSettingsChildren, settingsMain } from './settings';
import { allUsersChildren, usersMain } from './users';

export const allRouterPaths: NavigationItem[] = [
  crmMain,
  ...allCrmChildren,
  contentMain,
  ...allContentChildren,
  offerManagementMain,
  ...allOfferManagementChildren,
  riskManagementMain,
  ...allRiskManagementChildren,
  customerSearchMain,
  betTracerMain,
  matchesListMain,
  externalProductsMain,
  ...allExternalProductsChildren,
  usersMain,
  ...allUsersChildren,
  settingsMain,
  ...allSettingsChildren,
  otherEventView,
  otherCustomerDetails,
  otherUsersResetPassword,
  otherUsersCreateRole,
  otherUsersEditRole,
  otherUsersViewRole,
  otherUsersEditUser
];
