import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageError } from '@sap/logic/api-access/errors/message-error';
import { ApiMessageSignal } from '@sap/logic/api-access/successes/api-message-success-signal';
import { MessageSuccess } from '@sap/logic/api-access/successes/message-success';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { filter, switchMap, tap, timer } from 'rxjs';

@Component({
  selector: 'sap-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['toast.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ToastComponent extends Unsubscribable implements OnInit {
  public toastMessageSuccess: MessageSuccess | undefined = undefined;
  public toastMessageError: MessageError | undefined = undefined;
  public toastCount: number = 1;
  public toastErrorCount: number = 1;

  private _set: SetAndDetect<this>;
  private _delay: number = 5000;

  constructor(
    private _apiMessageSignal: ApiMessageSignal,
    private _cdRef: ChangeDetectorRef
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }

  public ngOnInit(): void {
    this._sub = this._apiMessageSignal.successMessage$
      .pipe(
        tap(this._set('toastMessageSuccess')),
        filter(Boolean),
        switchMap(() => {
          this.toastCount++;
          return timer(this._delay);
        })
      )
      .subscribe(() => {
        this.toastCount = 1;
        this._apiMessageSignal.successMessage$.next(undefined);
      });
    this._sub = this._apiMessageSignal.errorMessage$
      .pipe(
        tap(this._set('toastMessageError')),
        filter(Boolean),
        switchMap(() => {
          this.toastErrorCount++;
          return timer(this._delay);
        })
      )
      .subscribe(() => {
        this.toastErrorCount = 1;
        this._apiMessageSignal.errorMessage$.next(undefined);
      });

    this._cdRef.detectChanges();
  }

  public doClose(): void {
    this.toastCount = 1;
    this._apiMessageSignal.successMessage$.next(undefined);
  }

  public doCloseError(): void {
    this.toastErrorCount = 1;
    this._apiMessageSignal.errorMessage$.next(undefined);
  }
}
