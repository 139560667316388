import { SessionStorageKeys } from '@sap/shared/enums';
import { NavigationItem } from '../left-navigation-drawer.component';
import { brandsWithoutDefault } from './brand';

export const maxOpenedTabsForBetTracer: number = 6;

export const betTracerMain: NavigationItem = {
  title: 'Bet Tracer',
  url: '/bet-tracer',
  icon: 'cash',
  queryParams: { limit: 100 },
  scope: 'betTracer.main',
  brandsScope: brandsWithoutDefault,
  sessionStorageKeyToRemoveOnClose: SessionStorageKeys.scrollTopPositions
};
