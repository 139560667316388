// eslint-disable-next-line @typescript-eslint/typedef
import { BrandType } from '@sap/shared/enums';

export const environmentBase = {
  releaseVersion: '0.1.4',
  defaultLanguage: 'pl',
  brandsConfig: {
    order: [BrandType.default, BrandType.lvbetPl, BrandType.lvbetCom, BrandType.lvbetLv, BrandType.letsbetmdCom],
    defaultCount: 5
  }
};
