import { Environment } from '@sap/shared-settings/types';
import { environmentBase } from 'configs/environments/beefee/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: false,
  authApiUrl: 'https://auth.bp.testowaplatforma123.net/',
  offerApiUrl: 'https://offer-api.bp.testowaplatforma123.net/',
  offerMgrUrl: 'https://offer-mgr.bp.testowaplatforma123.net/',
  translationsMgrUrl: 'https://translation-mgr.bp.testowaplatforma123.net/',
  manualApi: 'https://manual-api.bp.testowaplatforma123.net/',
  clientReportingApiUrl: 'https://{brandName}-brand-reports.bp.testowaplatforma123.net/',
  clientBOApiUrl: 'https://client-bo-api-{brandName}.bp.testowaplatforma123.net/',
  socketUrl: 'wss://backoffice-ws.bp.testowaplatforma123.net/_v3/ws/update/',
  firebaseConfig: {
    apiKey: 'AIzaSyCC6TA3ojV29VlMXEoRI3OC4l3V3-vzVXo',
    authDomain: 'k8s-sportsbook.firebaseapp.com',
    projectId: 'k8s-sportsbook'
  }
};
