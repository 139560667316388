import { Inject, Injectable } from '@angular/core';
import { LOCALSTORAGE } from '@sap/shared-platform/providers/local-storage-provider';
import { LocalStorageKeys } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(@Inject(LOCALSTORAGE) private _localStorage: Storage) {}

  clear(): void {
    this._localStorage.clear();
  }

  getString(key: LocalStorageKeys): string | undefined {
    return this._localStorage.getItem(key) ?? undefined;
  }

  setString(key: LocalStorageKeys, value: string): void {
    this._localStorage.setItem(key, value);
  }

  getNumber(key: LocalStorageKeys): number | undefined {
    return this._localStorage.getItem(key) ? Number(this._localStorage.getItem(key)) : undefined;
  }

  setNumber(key: LocalStorageKeys, value: number): void {
    this._localStorage.setItem(key, value?.toString());
  }

  getBoolean(key: LocalStorageKeys): boolean {
    return this._localStorage.getItem(key) === 'true';
  }

  setBoolean(key: LocalStorageKeys, value: boolean): void {
    this._localStorage.setItem(key, value.toString());
  }

  getJson<T>(key: LocalStorageKeys): T | undefined {
    const value: string | null = this._localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  setJson<T>(key: LocalStorageKeys, value: T): void {
    this._localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: LocalStorageKeys): void {
    this._localStorage.removeItem(key);
  }
}
