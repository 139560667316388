import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

export enum Theme {
  dark = 'dark-theme',
  light = 'light-theme'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends Unsubscribable {
  public doSetTheme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(Theme.dark);

  public get isDarkThemeActive(): boolean {
    return this.doSetTheme$.getValue() === Theme.dark ? true : false;
  }

  constructor(@Inject(DOCUMENT) private _document: Document) {
    super();
    this._init();
  }

  private _init(): void {
    this._sub = this.doSetTheme$.pipe(distinctUntilChanged()).subscribe((theme: Theme) => {
      if (theme === Theme.light) {
        this._document.body.classList.remove(Theme.dark);
        this._document.body.classList.add(Theme.light);
      } else {
        this._document.body.classList.remove(Theme.light);
        this._document.body.classList.add(Theme.dark);
      }
    });
  }
}
