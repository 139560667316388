<header class="header" fxThemeCss>
  <div class="hamburger">
    <sapds-button iconName="hamburger-menu" size="large"> </sapds-button>
    <sapds-icon
      class="nav-icon-close nav-icon-close_all u-clickable"
      [class.u-disabled]="routerVisitedUrls.length === 1"
      fontWeight="bold"
      iconName="x"
      title="Close all tabs."
      (click)="$event.stopImmediatePropagation(); closeAllTab()"
    />
  </div>
  <ul horizontalScroll [currentUrl]="currentUrl" class="nav nav-tabs">
    <ng-container *ngFor="let visitedUrl of routerVisitedUrls; let index = index">
      <li
        class="nav-item"
        [class.active]="visitedUrl | isActiveTab: currentUrl : currentParams"
        [attr.data-url]="visitedUrl.url"
        [routerLink]="[visitedUrl.url]"
        [queryParams]="visitedUrl.queryParams ? visitedUrl.queryParams : null"
      >
        <sapds-icon [iconName]="visitedUrl.icon" class="nav-icon" />

        <a class="nav-link">{{ visitedUrl.title }}</a>
        <sapds-icon
          class="nav-icon-close nav-icon-close_single u-clickable"
          [class.u-disabled]="routerVisitedUrls.length === 1"
          fontWeight="bold"
          iconName="x"
          (click)="$event.stopImmediatePropagation(); closeTab(index, visitedUrl.url === currentUrl)"
        />
      </li>
    </ng-container>
  </ul>
  <div class="hamburger hamburger_right">
    <sapds-button iconName="hamburger-menu" size="large" (click)="doToggleFiltersSidebar.next(!isFiltersExpanded)">
    </sapds-button>
  </div>
</header>
