import { BrandType } from '@sap/shared/enums';

export class Country {
  constructor(
    public readonly code: string,
    public readonly name: string
  ) {}

  public static fromJson(json: Record<string, string>): Country {
    return new Country(json['code'].toLowerCase(), json['name']);
  }

  public static fromJsonArray(jsons: Record<string, string>[]): Country[] {
    return jsons.map(Country.fromJson);
  }
}

export class BrandInfo {
  constructor(
    public readonly name: BrandType,
    public readonly langs: string[],
    public readonly countries: Country[]
  ) {}

  public static fromJson(json: Record<string, any>): BrandInfo {
    return new BrandInfo(json['name'], json['languages'], Country.fromJsonArray(json['countries']));
  }

  public static fromJsonArray(jsons: Record<string, any>[]): BrandInfo[] {
    return jsons.map(BrandInfo.fromJson);
  }
}
