import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/*
  firefox dont support :host-context(), remove this directive from code when remove shadowDom
*/

@Directive({
  selector: '[fxThemeCss]'
})
export class FxThemeCssDirective implements OnInit, OnDestroy {
  private _mutationObserverBody?: MutationObserver | null;
  private _mutationObserverHtml?: MutationObserver | null;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit(): void {
    if (this._document.body.classList.contains('set-theme-marker')) {
      this._checkBodyClassList();
    } else {
      this._checkHtmlClassList();
    }
  }

  private _checkBodyClassList(): void {
    const hostElement: HTMLElement | undefined = this._elementRef.nativeElement?.parentNode?.host;
    if (!hostElement) {
      console.warn('NO HOST ELEMENT FOUND');
      return;
    }
    const check = (): void => {
      if (this._document.body.classList.contains('dark-theme')) {
        this._renderer.addClass(hostElement, 'dark-theme-local');
        this._renderer.removeClass(hostElement, 'light-theme-local');
      } else {
        this._renderer.addClass(hostElement, 'light-theme-local');
        this._renderer.removeClass(hostElement, 'dark-theme-local');
      }
    };
    check();
    this._mutationObserverBody = new MutationObserver(() => check());
    this._mutationObserverBody.observe(this._document.body, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  // BBP set theme css on html tag
  private _checkHtmlClassList(): void {
    const hostElement: HTMLElement | undefined = this._elementRef.nativeElement?.parentNode?.host;
    if (!hostElement) {
      console.warn('NO HOST ELEMENT FOUND');
      return;
    }
    const check = (): void => {
      if (this._document.querySelector('html')!.classList.contains('dark-theme')) {
        this._renderer.addClass(hostElement, 'dark-theme-local');
        this._renderer.removeClass(hostElement, 'light-theme-local');
      } else {
        this._renderer.addClass(hostElement, 'light-theme-local');
        this._renderer.removeClass(hostElement, 'dark-theme-local');
      }
    };
    check();
    this._mutationObserverHtml = new MutationObserver(() => check());
    this._mutationObserverHtml.observe(this._document.querySelector('html')!, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  public ngOnDestroy(): void {
    this._mutationObserverBody?.disconnect();
    this._mutationObserverHtml?.disconnect();
  }
}
