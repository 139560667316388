import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FxThemeCssDirective } from './fx-theme-css.directive';

@NgModule({
  declarations: [FxThemeCssDirective],
  imports: [CommonModule],
  exports: [FxThemeCssDirective]
})
export class FxThemeCssModule {}
