/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: async () => {
      const { BetTracerInstanceModule: BetTracerInstanceModule } = await import(
        /* webpackChunkName: "route-bet-tracer-instance" */ './bet-tracer-instance/bet-tracer-instance.module'
      );
      return BetTracerInstanceModule;
    }
  },
  {
    path: 'client/:id',
    loadChildren: async () => {
      const { BetTracerInstanceModule: BetTracerInstanceModule } = await import(
        /* webpackChunkName: "route-bet-tracer-instance" */ './bet-tracer-instance/bet-tracer-instance.module'
      );
      return BetTracerInstanceModule;
    }
  },
  {
    path: 'market/:id',
    loadChildren: async () => {
      const { BetTracerInstanceModule: BetTracerInstanceModule } = await import(
        /* webpackChunkName: "route-bet-tracer-instance" */ './bet-tracer-instance/bet-tracer-instance.module'
      );
      return BetTracerInstanceModule;
    }
  },
  {
    path: 'match/:id',
    loadChildren: async () => {
      const { BetTracerInstanceModule: BetTracerInstanceModule } = await import(
        /* webpackChunkName: "route-bet-tracer-instance" */ './bet-tracer-instance/bet-tracer-instance.module'
      );
      return BetTracerInstanceModule;
    }
  }
];

export const betTracerIndexInstances: number[] = [1, 2, 3, 4, 5];

betTracerIndexInstances.forEach((instance: number) =>
  routes.push({
    path: `${instance}`,
    loadChildren: async () => {
      const { BetTracerInstanceModule: BetTracerInstanceModule } = await import(
        /* webpackChunkName: "route-bet-tracer-instance" */ './bet-tracer-instance/bet-tracer-instance.module'
      );
      return BetTracerInstanceModule;
    }
  })
);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BetTracerPageRoutingModule {}
