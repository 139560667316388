import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import * as R from 'rambdax';

export type NgContextVars = Record<string, any>;
export class NgContext<T extends NgContextVars> {
  vars!: T;
  updateVar!: <K extends keyof T, V extends T[K]>(key: K, value: V) => void;
  updateVarObject!: <P extends keyof T, K extends keyof T[P], V extends T[P][K]>(
    key: P,
    objectKey: K,
    value: V
  ) => void;
  updateVarArray!: <P extends keyof T, V extends T[P][number]>(key: P, arrayIndex: number, valueToUpdate: V) => void;
}

/** @deprecated please create always templateVarTyped, check examples in code */
@Directive({
  selector: '[templateVar]',
  standalone: true
})
export class TemplateVarDirective<T extends NgContextVars> implements OnInit {
  protected _context: NgContext<T> = new NgContext<T>();
  protected _initialContext?: T;

  public static ngTemplateContextGuard<T extends NgContextVars>(
    _dir: TemplateVarDirective<T>,
    ctx: unknown
  ): ctx is NgContext<T> {
    return true;
  }

  @Input() templateVar?: T;

  constructor(
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<NgContext<T>>,
    protected _cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._initContextVars();
    this._context.updateVar = this.updateFn();
    this._context.updateVarObject = this.updateObjectFn();
    this._context.updateVarArray = this.updateArrayFn();
    this._createView();
  }

  private _initContextVars(): void {
    this._context.vars = this._initialContext ?? this.templateVar ?? ({} as T);
  }

  private _createView(): void {
    if (this._context.vars) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef, this._context);
    }
  }

  public updateFn =
    () =>
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    <K extends keyof T, V extends T[K]>(key: K, value: V): void => {
      this._context.vars = R.assoc(key as any, value, this._context.vars);
      this._cdRef.detectChanges();
    };

  public updateObjectFn =
    () =>
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    <P extends keyof T, K extends keyof T[P], V extends T[P][K]>(key: P, objectKey: K, value: V): void => {
      const ObjectNestedUpdated: Record<any, V> & T[P] = R.assoc(objectKey as any, value, this._context.vars[key]);
      this._context.vars = R.assoc(key as any, ObjectNestedUpdated, this._context.vars);
      this._cdRef.detectChanges();
    };

  public updateArrayFn =
    () =>
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    <P extends keyof T, V extends T[P][number]>(key: P, arrayIndex: number, valueToUpdate: V): void => {
      const ArrayNestedUpdated: V[] = R.adjust(arrayIndex, () => valueToUpdate, this._context.vars[key]);
      this._context.vars = R.assoc(key as any, ArrayNestedUpdated, this._context.vars);
      this._cdRef.detectChanges();
    };

  public updateInitialContext(data: Partial<T>): void {
    this._context.vars = { ...this._context.vars, ...data };
    this._cdRef.detectChanges();
  }

  public resetToInitialContext(): void {
    this._initContextVars();
    this._cdRef.detectChanges();
  }
}
