import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageError } from '../errors/message-error';
import { MessageSuccess } from './message-success';

@Injectable({
  providedIn: 'root'
})
export class ApiMessageSignal {
  public successMessage$: Subject<MessageSuccess | undefined> = new Subject<MessageSuccess | undefined>();
  public errorMessage$: Subject<MessageError | undefined> = new Subject<MessageError | undefined>();
}
