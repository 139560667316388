import { NavigationItem } from '../left-navigation-drawer.component';

export const riskManagementMain: NavigationItem = {
  title: 'Risk Management',
  url: undefined,
  icon: 'balance-scale',
  scope: 'riskManagement.main'
};

export const riskManagementMarketClasses: NavigationItem = {
  title: 'Market Classes',
  url: '/market-classes',
  icon: 'layers',
  scope: 'riskManagement.marketClassesSetup',
  queryParams: null
};

export const riskManagementCreateProfile: NavigationItem = {
  title: 'Create Profile',
  url: '/create-profile',
  icon: 'contact',
  scope: 'riskManagement.profileCreate'
};

export const riskManagementEditProfile: NavigationItem = {
  title: 'Edit Profile',
  url: '/edit-profile',
  icon: 'contact',
  scope: 'riskManagement.profileEdit'
};

export const riskManagementResultingManager: NavigationItem = {
  title: 'Resulting Manager',
  url: '/resulting-manager',
  icon: 'wallet',
  scope: 'riskManagement.resultingManager'
};
// export const riskManagementFailedTransactions: NavigationItem = // { TODO
//   title: 'Failed Transactions',
//   url: paths.resultingManager,
//   icon: 'handshake-o',
//   text: 'RM',
//   ...offerApiBadge,
//   scope: 'riskManagement.failedTransactions'
// }
export const riskManagementMaintenanceControl: NavigationItem = {
  title: 'Maintenance Control',
  url: '/maintenance',
  icon: 'applications-settings',
  scope: 'riskManagement.maintenance'
};

export const allRiskManagementChildren: NavigationItem[] = [
  riskManagementMarketClasses,
  riskManagementCreateProfile,
  riskManagementEditProfile,
  riskManagementResultingManager,
  riskManagementMaintenanceControl
];
