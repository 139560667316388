import { Injectable } from '@angular/core';
import { AuthState } from '@sap/logic/auth/services/auth-state';
import { BrandInfo } from '@sap/logic/shared/models/brand-info';
import { Provider } from '@sap/logic/shared/models/provider';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { mapToOrderedBrandInfoAndApiAvailable } from '@sap/shared/miscs/utils';
import { from, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { InfoRepository } from '../repository/info-repository';

@Injectable({ providedIn: 'root' })
export class InfoRepositoryCached {
  private _getBrandsInfoShareRef$!: Observable<BrandInfo[]>;
  private _getProvidersInfoShareRef$!: Observable<Provider[]>;

  constructor(
    private _authState: AuthState,
    private _infoRepository: InfoRepository,
    private _sharedSettings: SharedSettings
  ) {}

  public getBrandsInfo(): Observable<BrandInfo[]> {
    return (this._getBrandsInfoShareRef$ ??= this._authState.isLoggedInSignal().pipe(
      mergeMap(() => from(this._infoRepository.getBrands())),
      map(mapToOrderedBrandInfoAndApiAvailable(this._sharedSettings.environment.brandsConfig.order)),
      shareReplay(1)
    ));
  }

  public getProvidersInfo(): Observable<Provider[]> {
    return (this._getProvidersInfoShareRef$ ??= from(this._infoRepository.getProviders()).pipe(shareReplay(1)));
  }
}
