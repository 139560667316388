import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sap-right-filters-drawer',
  templateUrl: './right-filters-drawer.component.html',
  styleUrls: ['./right-filters-drawer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class RightFiltersDrawerComponent {
  private _isExpanded: boolean = false;

  @Input() set isExpanded(value: boolean) {
    this._isExpanded = value;
    this._cdRef.detectChanges();
  }
  public get isExpanded(): boolean {
    return this._isExpanded;
  }
  @Output() doToggleMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _cdRef: ChangeDetectorRef) {}
}
