import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Params } from '@angular/router';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { RouterPlacesService } from '@sap/ui/shared/services/router-places.service';
import { NavigationItem } from '../left-navigation-drawer/left-navigation-drawer.component';
import { matchesListMain } from '../left-navigation-drawer/navigation-items/matches-list';

@Component({
  selector: 'sap-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class TopNavigationComponent extends Unsubscribable implements OnInit {
  private _set: SetAndDetect<this>;

  @Input() isFiltersExpanded: boolean = true;
  @Output() doToggleFiltersSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  public currentUrl: string | undefined;
  public currentParams: Params | undefined;
  public routerVisitedUrls: NavigationItem[] = [];

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _routerPlacesService: RouterPlacesService,
    private _sessionStorage: SessionStorageService
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }

  public ngOnInit(): void {
    this._sub = this._routerPlacesService.getRouterVisitedUrls().subscribe(this._set('routerVisitedUrls'));
    this._sub = this._routerPlacesService.getCurrentUrl().subscribe(this._set('currentUrl'));
    this._sub = this._routerPlacesService.getCurrentParams().subscribe(this._set('currentParams'));
  }

  public closeTab(index: number, isActive: boolean): void {
    const newRouterVisitedUrls: NavigationItem[] = this.routerVisitedUrls
      .slice(0, index)
      .concat(this.routerVisitedUrls.slice(index + 1));
    this._sessionStorage.clearSessionsKeysAfterCloseNavItem(this.routerVisitedUrls[index]);
    this._routerPlacesService.setNewRouterVisitedUrls(newRouterVisitedUrls);
    if (isActive) {
      const newActiveIndex: number = index ? index - 1 : 0;
      this._routerPlacesService.setNewActiveRouterUrl(newRouterVisitedUrls[newActiveIndex]);
    }
  }

  public closeAllTab(): void {
    this._routerPlacesService.setNewRouterVisitedUrls([matchesListMain]);
    this._routerPlacesService.setNewActiveRouterUrl(matchesListMain);
  }
}
