import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { SessionStorageKeys } from '@sap/shared/enums';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import { Subject, debounceTime, delay, filter, fromEvent, skip, switchMap } from 'rxjs';

@Directive({
  selector: '[storeScroll]',
  standalone: true
})
export class StoreScrollDirective extends Unsubscribable implements AfterViewInit {
  private _currentUrlValue: string | undefined;
  private _currentUrl$: Subject<string | undefined> = new Subject();

  @Input() set currentUrl(currentUrl: string | undefined) {
    this._currentUrlValue = currentUrl;
    this._currentUrl$.next(currentUrl);
  }

  constructor(
    private _element: ElementRef<HTMLUListElement>,
    private _sessionStorage: SessionStorageService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this._sub = this._currentUrl$.pipe(filter(Boolean), delay(1000)).subscribe((currentUrl: string) => {
      const storedScrollPositions: Record<string, number> = this._getScrollTopFromSessionStorage() ?? {};
      if (isDefined(storedScrollPositions[currentUrl])) {
        this._element.nativeElement.scrollTop = storedScrollPositions[currentUrl];
      }
    });

    this._sub = this._currentUrl$
      .pipe(switchMap(() => fromEvent(this._element.nativeElement, 'scroll').pipe(skip(1), debounceTime(500))))
      .subscribe(() => this._saveScrollTopInSessionStorage());
  }

  private _saveScrollTopInSessionStorage(): void {
    if (this._currentUrlValue) {
      const storedScrollPositions: Record<string, number> = this._getScrollTopFromSessionStorage() ?? {};
      this._sessionStorage.setJson(SessionStorageKeys.scrollTopPositions, {
        ...storedScrollPositions,
        [this._currentUrlValue]: this._element.nativeElement.scrollTop
      });
    }
  }

  private _getScrollTopFromSessionStorage(): Record<string, number> | undefined {
    return this._sessionStorage.getJson(SessionStorageKeys.scrollTopPositions);
  }
}
