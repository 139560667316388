import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IconName } from './icon-types';

@Component({
  selector: 'sapds-icon',
  standalone: true,
  imports: [],
  styleUrls: ['./icon.component.scss'],
  template: ` <i [class]="cssClass"></i>`,
  encapsulation: ViewEncapsulation.Emulated
})
export class IconComponent {
  @Input({ required: true }) iconName!: IconName;
  @Input() size: 'large' | 'x-small' | 'small' | 'default' = 'default';
  @Input() fontWeight: 'normal' | 'bold' = 'normal';

  public get cssClass(): string {
    return [`icon icon_${this.size} icon_${this.fontWeight}`, `cil-${this.iconName}`].join(' ');
  }
}
