export const cast =
  <T>() =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (value: any): T =>
    value as T;

export function objHasKey<T extends object>(obj: T, key: string | number): boolean {
  return obj.hasOwnProperty(key);
}

export function isObject<T>(obj: T): boolean {
  return typeof obj === 'function' || (typeof obj === 'object' && Boolean(obj));
}

export function isString<T>(input: T): boolean {
  return typeof input === 'string' || input instanceof String;
}

/** @deprecated doesn't have type guard, please use isDefined function  */
export function isUndefined<T>(value: T): boolean {
  return typeof value === 'undefined';
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined';
}

export function isStringAndNoEmpty<T>(value: T | undefined): boolean {
  return isString(value) && value !== '';
}

export const atIndex =
  <T>(index: number) =>
  (arr: T[]): T =>
    arr[index] as T;

export function createTuple2<A, B>(a: A, b: B): [A, B] {
  return [a, b];
}

export function createTuple3<A, B, C>(a: A, b: B, c: C): [A, B, C] {
  return [a, b, c];
}

export const createTuple2C =
  <A, B>(b: B) =>
  (a: A): [A, B] =>
    createTuple2(a, b);
export function groupByAsObjectSingle<T, V extends string | number>(
  array: T[],
  keyGetter: (item: T) => V
): Record<V, T> {
  const groupObject: Record<V, T> = {} as Record<V, T>;
  array.forEach((item: T) => {
    const key: V = keyGetter(item);
    groupObject[key] = item;
  });
  return groupObject;
}

export const groupByAsObjectSingleC =
  <T, V extends string | number>(keyGetter: (item: T) => V) =>
  (array: T[]): Record<V, T> =>
    groupByAsObjectSingle(array, keyGetter);

export const equalsSimple = <T>(a: T, b: T): boolean => a === b;

export const lengthGt =
  (greaterThen: number) =>
  (data: string | undefined): boolean =>
    (data?.length ?? 0) > greaterThen;

import { HttpParams } from '@angular/common/http';
import { FilterValue } from '@sap/ui/shared/types/filter-value';

export function mapToQueryParamsFilters<T>(
  mappedFilters: Record<string, string>,
  filters?: T,
  httpParams?: {
    [param: string]: string | number | boolean | readonly (string | number | boolean)[];
  },
  mappedValues?: Record<string, string>
): HttpParams {
  let params: HttpParams = new HttpParams(httpParams ? { fromObject: httpParams } : undefined);

  if (Object.keys(filters ?? {}).length === 0) {
    return params;
  }
  for (const key of Object.keys(mappedFilters)) {
    if (filters![key] !== undefined) {
      const value: string = mappedValues
        ? Array.isArray(filters![key])
          ? filters![key].map((filterValue: any) => mappedValues[filterValue] ?? filterValue)
          : mappedValues?.[filters![key]] ?? filters![key]
        : filters![key];
      params = params.append(mappedFilters[key], value);
    }
  }
  return params;
}

export function mapObjectKeysToDefinedKeys<T extends object>(
  mappedKeys: Record<string, string>,
  entryObject: T
): Record<string, FilterValue> {
  const mappedObject: Record<string, FilterValue> = {};

  for (const key of Object.keys(mappedKeys)) {
    if (isDefined(entryObject[key])) {
      mappedObject[mappedKeys[key]] = entryObject[key];
    }
  }
  return mappedObject;
}

export function mapUpdatedRecord<T>(records: T[], updatedRecord: T, propToCompare: string = 'id'): T[] {
  return records.map((record: T) => {
    if (record[propToCompare] === updatedRecord[propToCompare]) {
      return {
        ...record,
        ...updatedRecord
      };
    }
    return record;
  });
}

export function mapToSportsGroupsQueryParams(
  filters: Record<string, any> | undefined,
  key: string = 'sportsGroupsIds'
): void {
  // We should request for Sports Groups using only one identifier and we use always last
  if (filters && Array.isArray(filters[key]) && filters[key]!.length > 0) {
    filters[key] = [filters[key].at(-1)!];
  }
}
