/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PanelContainerComponent } from '@sap/ui/containers/panel-container/panel-container.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: async () => {
      const { LoginPageModule: LoginPageModule } = await import(
        /* webpackChunkName: "route-login-page" */ '@sap/ui/pages/login-page/login-page.module'
      );
      return LoginPageModule;
    }
  },
  {
    path: 'dashboard',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: PanelContainerComponent,
    children: [
      {
        path: 'matches-list',
        loadChildren: async () => {
          const { MatchesListPageModule: MatchesListPageModule } = await import(
            /* webpackChunkName: "route-matches-list-page" */ '@sap/ui/pages/matches-list-page/matches-list-page.module'
          );
          return MatchesListPageModule;
        }
      },
      {
        path: 'streamings',
        loadChildren: async () => {
          const { StreamingsPageModule: StreamingsPageModule } = await import(
            /* webpackChunkName: "route-streamings-page" */ '@sap/ui/pages/streamings-page/streamings-page.module'
          );
          return StreamingsPageModule;
        }
      },
      {
        path: 'provider-streamings',
        loadChildren: async () => {
          const { ProviderStreamingsPageModule: ProviderStreamingsPageModule } = await import(
            /* webpackChunkName: "route-provider-streamings-page" */ '@sap/ui/pages/provider-streamings-page/provider-streamings-page.module'
          );
          return ProviderStreamingsPageModule;
        }
      },
      {
        path: 'provider-matches',
        loadChildren: async () => {
          const { ProviderMatchesPageModule: ProviderMatchesPageModule } = await import(
            /* webpackChunkName: "route-provider-matches-page" */ '@sap/ui/pages/provider-matches-page/provider-matches-page.module'
          );
          return ProviderMatchesPageModule;
        }
      },
      {
        path: 'sports-groups-sorting',
        loadChildren: async () => {
          const { SportsGroupsSortingPageModule: SportsGroupsSortingPageModule } = await import(
            /* webpackChunkName: "route-sports-groups-sorting-page" */ '@sap/ui/pages/sports-groups-sorting-page/sports-groups-sorting-page.module'
          );
          return SportsGroupsSortingPageModule;
        }
      },
      {
        path: 'sports-groups-restrictions',
        loadChildren: async () => {
          const { SportsGroupsRestrictionsPageModule: SportsGroupsRestrictionsPageModule } = await import(
            /* webpackChunkName: "route-sports-groups-restrictions-page" */ '@sap/ui/pages/sports-groups-restrictions-page/sports-groups-restrictions-page.module'
          );
          return SportsGroupsRestrictionsPageModule;
        }
      },
      {
        path: 'supply-manager',
        loadChildren: async () => {
          const { SupplyManagerPageModule: SupplyManagerPageModule } = await import(
            /* webpackChunkName: "route-supply-manager-page" */ '@sap/ui/pages/supply-manager-page/supply-manager-page.module'
          );
          return SupplyManagerPageModule;
        }
      },
      {
        path: 'supply-matches',
        loadChildren: async () => {
          const { MatchesSupplyPageModule: MatchesSupplyPageModule } = await import(
            /* webpackChunkName: "route-matches-supply-page" */ '@sap/ui/pages/matches-supply-page/matches-supply-page.module'
          );
          return MatchesSupplyPageModule;
        }
      },
      {
        path: 'recommended',
        loadChildren: async () => {
          const { PromotedEventsPageModule: PromotedEventsPageModule } = await import(
            /* webpackChunkName: "route-promoted-events-page" */ '@sap/ui/pages/promoted-events-page/promoted-events-page.module'
          );
          return PromotedEventsPageModule;
        }
      },
      {
        path: 'esport-recommended',
        loadChildren: async () => {
          const { PromotedEventsEsportPageModule: PromotedEventsEsportPageModule } = await import(
            /* webpackChunkName: "route-promoted-events-esport-page" */ '@sap/ui/pages/promoted-events-esport-page/promoted-events-esport-page.module'
          );
          return PromotedEventsEsportPageModule;
        }
      },
      {
        path: 'event-view/:matchId',
        loadChildren: async () => {
          const { MatchOfferSettingsPageModule: MatchOfferSettingsPageModule } = await import(
            /* webpackChunkName: "route-match-offer-settings-page" */ '@sap/ui/pages/match-offer-settings-page/match-offer-settings-page.module'
          );
          return MatchOfferSettingsPageModule;
        }
      },
      {
        path: 'resulting-manager',
        loadChildren: async () => {
          const { ResultingManagerPageModule: ResultingManagerPageModule } = await import(
            /* webpackChunkName: "route-resulting-manager-page" */ '@sap/ui/pages/resulting-manager-page/resulting-manager-page.module'
          );
          return ResultingManagerPageModule;
        }
      },
      {
        path: 'market-classes',
        loadChildren: async () => {
          const { MarketClassesSetupPageModule: MarketClassesSetupPageModule } = await import(
            /* webpackChunkName: "route-market-classes-setup-page" */ '@sap/ui/pages/market-classes-setup-page/market-classes-setup-page.module'
          );
          return MarketClassesSetupPageModule;
        }
      },
      {
        path: 'market-groups',
        loadChildren: async () => {
          const { MarketGroupsPageModule: MarketGroupsPageModule } = await import(
            /* webpackChunkName: "route-market-groups-setup-page" */ '@sap/ui/pages/market-groups-page/market-groups-page.module'
          );
          return MarketGroupsPageModule;
        }
      },
      {
        path: 'primary-columns',
        loadChildren: async () => {
          const { PrimaryColumnsPageModule: PrimaryColumnsPageModule } = await import(
            /* webpackChunkName: "route-primary-columns-setup-page" */ '@sap/ui/pages/primary-columns-page/primary-columns-page.module'
          );
          return PrimaryColumnsPageModule;
        }
      },
      {
        path: 'api-tokens',
        loadChildren: async () => {
          const { ApiTokensPageModule: ApiTokensPageModule } = await import(
            /* webpackChunkName: "route-api-tokens-page" */ '@sap/ui/pages/api-tokens-page/api-tokens-page.module'
          );
          return ApiTokensPageModule;
        }
      },
      // {
      //   path: 'client-reporting',
      //   loadChildren: async () => {
      //     const { ClientReportingPageModule: ClientReportingPageModule } = await import(
      //       /* webpackChunkName: "route-client-reporting-page" */ '@sap/ui/pages/client-reporting-page/client-reporting-page.module'
      //     );
      //     return ClientReportingPageModule;
      //   }
      // },
      {
        path: 'customer-search',
        loadChildren: async () => {
          const { CustomerSearchPageModule: CustomerSearchPageModule } = await import(
            /* webpackChunkName: "route-customer-search-page" */ '@sap/ui/pages/customer-search-page/customer-search-page.module'
          );
          return CustomerSearchPageModule;
        }
      },
      {
        path: 'customer-details',
        loadChildren: async () => {
          const { CustomerDetailsPageModule: CustomerDetailsPageModule } = await import(
            /* webpackChunkName: "route-customer-details-page" */ '@sap/ui/pages/customer-details-page/customer-details-page.module'
          );
          return CustomerDetailsPageModule;
        }
      },
      {
        path: 'maintenance',
        loadChildren: async () => {
          const { MaintenancePageModule: MaintenancePageModule } = await import(
            /* webpackChunkName: "route-maintenance-page" */ '@sap/ui/pages/maintenance-page/maintenance-page.module'
          );
          return MaintenancePageModule;
        }
      },
      {
        path: 'bet-tracer',
        loadChildren: async () => {
          const { BetTracerPageModule: BetTracerPageModule } = await import(
            /* webpackChunkName: "route-bet-tracer-page" */ '@sap/ui/pages/bet-tracer-page/bet-tracer-page.module'
          );
          return BetTracerPageModule;
        }
      },
      {
        path: 'users-list',
        loadChildren: async () => {
          const { UsersListPageModule: UsersListPageModule } = await import(
            /* webpackChunkName: "route-users-list-page" */ '@sap/ui/pages/users-pages/users-list-page.module'
          );
          return UsersListPageModule;
        }
      },
      {
        path: 'users',
        loadChildren: async () => {
          const { UsersPageModule: UsersPageModule } = await import(
            /* webpackChunkName: "route-users-page" */ '@sap/ui/pages/users-pages/users-page.module'
          );
          return UsersPageModule;
        }
      },
      {
        path: 'event-restrictions',
        loadChildren: async () => {
          const { EventRestrictionsPageModule: EventRestrictionsPageModule } = await import(
            /* webpackChunkName: "route-event-restrictions-page" */ '@sap/ui/pages/event-restrictions-page/event-restrictions-page.module'
          );
          return EventRestrictionsPageModule;
        }
      },
      {
        path: 'create-profile',
        loadChildren: async () => {
          const { CreateProfilePageModule: CreateProfilePageModule } = await import(
            /* webpackChunkName: "route-users-page" */ '@sap/ui/pages/create-profile-page/create-profile-page.module'
          );
          return CreateProfilePageModule;
        }
      },
      {
        path: 'edit-profile',
        loadChildren: async () => {
          const { EditProfilePageModule: EditProfilePageModule } = await import(
            /* webpackChunkName: "profiles-config-page" */ '@sap/ui/pages/edit-profile-page/edit-profile-page.module'
          );
          return EditProfilePageModule;
        }
      },
      {
        path: 'competitions-mapping',
        loadChildren: async () => {
          const { CompetitionMappingPageModule: CompetitionMappingPageModule } = await import(
            /* webpackChunkName: "route-competition-mapping-page" */ '@sap/ui/pages/competition-mapping-page/competition-mapping-page.module'
          );
          return CompetitionMappingPageModule;
        }
      },
      {
        path: 'create-manual-event',
        loadChildren: async () => {
          const { CreateEventPageModule: CreateEventPageModule } = await import(
            /* webpackChunkName: "route-competition-mapping-page" */ '@sap/ui/pages/create-event-page/create-event-page.module'
          );
          return CreateEventPageModule;
        }
      },
      {
        path: 'create-manual-market',
        loadChildren: async () => {
          const { CreateMarketPageModule: CreateMarketPageModule } = await import(
            /* webpackChunkName: "route-competition-mapping-page" */ '@sap/ui/pages/create-market-page/create-market-page.module'
          );
          return CreateMarketPageModule;
        }
      },
      {
        path: 'country-limits',
        loadChildren: async () => {
          const { CountryLimitsPageModule: CountryLimitsPageModule } = await import(
            /* webpackChunkName: "route-country-limits-page" */ '@sap/ui/pages/country-limits-page/country-limits-page.module'
          );
          return CountryLimitsPageModule;
        }
      },
      {
        path: 'locations',
        loadChildren: async () => {
          const { LocationsPageModule: LocationsPageModule } = await import(
            /* webpackChunkName: "route-locations-page" */ '@sap/ui/pages/locations-page/locations-page.module'
          );
          return LocationsPageModule;
        }
      },
      {
        path: 'terminals',
        loadChildren: async () => {
          const { TerminalsPageModule: TerminalsPageModule } = await import(
            /* webpackChunkName: "route-terminals-page" */ '@sap/ui/pages/terminals-page/terminals-page.module'
          );
          return TerminalsPageModule;
        }
      },
      {
        path: 'agents',
        loadChildren: async () => {
          const { AgentsPageModule: AgentsPageModule } = await import(
            /* webpackChunkName: "route-agents-page" */ '@sap/ui/pages/agents-page/agents-page.module'
          );
          return AgentsPageModule;
        }
      },
      {
        path: 'lower-tax',
        loadChildren: async () => {
          const { LowerTaxPageModule: LowerTaxPageModule } = await import(
            /* webpackChunkName: "route-lower-tax-page" */ '@sap/ui/pages/lower-tax-page/lower-tax-page.module'
          );
          return LowerTaxPageModule;
        }
      },
      {
        path: 'rate-boost',
        loadChildren: async () => {
          const { RateBoostPageModule: RateBoostPageModule } = await import(
            /* webpackChunkName: "route-rate-boost-page" */ '@sap/ui/pages/rate-boost-page/rate-boost-page.module'
          );
          return RateBoostPageModule;
        }
      },
      {
        path: 'translations',
        loadChildren: async () => {
          const { TranslationsPageModule: TranslationsPageModule } = await import(
            /* webpackChunkName: "route-translations-page" */ '@sap/ui/pages/translations-page/translations-page.module'
          );
          return TranslationsPageModule;
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
