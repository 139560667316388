import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@sap/logic/auth/services/auth-state';
import { SportsGroupsLoader } from '@sap/logic/database/sports-groups-loader';
import { UserDatabase } from '@sap/logic/firestore-database/user-database';
import { UserDatabaseSettings, UserTheme } from '@sap/logic/shared/models/user-database-settings';
import { ModuleScopes } from '@sap/logic/shared/types/permissions';
import { BrandSettings } from '@sap/shared-settings/brand-settings';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { BrandType } from '@sap/shared/enums';
import { tapTransferRxNext, transferRxNext } from '@sap/shared/helpers/rx-helpers';
import { flattenWithLatestFrom21 } from '@sap/shared/helpers/rx-operators';
import { NavigationItem } from '@sap/ui/navs/left-navigation-drawer/left-navigation-drawer.component';
import { RouterPlacesService } from '@sap/ui/shared/services/router-places.service';
import { Theme, ThemeService } from '@sap/ui/shared/services/set-theme.service';
import { Observable, ReplaySubject, combineLatest, map, shareReplay, withLatestFrom } from 'rxjs';
import { PanelContainerFeedValues } from './panel-container-feed-values';

@Injectable()
export class PanelContainerBloc extends Unsubscribable {
  private _doLogout$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private _userEmail$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _doToggleMenu$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _doToggleTheme$: ReplaySubject<Theme> = new ReplaySubject<Theme>(1);
  private _doToggleFilters$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _doExpandSubMenu$: ReplaySubject<Record<number, boolean>> = new ReplaySubject<Record<number, boolean>>(1);
  private _getUserSettingsShareRef$!: Observable<UserDatabaseSettings>;

  public feedValues: PanelContainerFeedValues = {
    userEmail: this._userEmail(),
    currentUrl: this._currentUrl(),
    currentTheme: this._getSelectedUserTheme(),
    isNotLoggedInSignal: this._isNotLoggedInSignal(),
    userSettings: this._getUserSettings(),
    navigationItems: this._routerVisitedUrls(),
    doLogout$: this._doLogout$,
    doToggleMenu$: this._doToggleMenu$,
    doToggleTheme$: this._doToggleTheme$,
    doExpandSubMenu$: this._doExpandSubMenu$,
    doToggleFilters$: this._doToggleFilters$
  };

  constructor(
    private _router: Router,
    private _authState: AuthState,
    private _sportsGroupsLoader: SportsGroupsLoader,
    private _brandSettings: BrandSettings,
    private _routerPlacesService: RouterPlacesService,
    private _userDatabase: UserDatabase,
    private _themeService: ThemeService
  ) {
    super();
    this._init();
  }

  private _init(): void {
    this._sub = this._authState.getUserEmail().subscribe(transferRxNext(this._userEmail$));
    this._initLoadSportsGroupOnLogin();
    this._initBrandSettingsOnLogin();
    this._handleLogout();
    this._handleToggleTheme();
    this._handleToggleMenu();
    this._handleToggleFilters();
    this._handleExpandSubMenu();
    this._doUpdateUserPermissions();
  }

  private _isNotLoggedInSignal(): Observable<void> {
    return this._authState.isNotLoggedInSignal();
  }

  private _userEmail(): Observable<string> {
    return this._userEmail$;
  }

  private _currentUrl(): Observable<string> {
    return this._routerPlacesService.getCurrentUrl();
  }

  private _getUserSettings(): Observable<UserDatabaseSettings> {
    return (this._getUserSettingsShareRef$ ??= this._userDatabase.getUserSettings().pipe(shareReplay(1)));
  }

  private _routerVisitedUrls(): Observable<NavigationItem[]> {
    return this._routerPlacesService.getRouterVisitedUrls();
  }

  private _getUserThemeSettings(): Observable<UserTheme> {
    return this._getUserSettings().pipe(map((settings: UserDatabaseSettings) => settings.uiTheme));
  }

  private _getSelectedUserTheme(): Observable<Theme> {
    return combineLatest([this._getUserSettings(), this._currentUrl()]).pipe(
      map(([settings, url]: [UserDatabaseSettings, string]) => settings.uiTheme[url] ?? Theme.dark),
      tapTransferRxNext(this._themeService.doSetTheme$)
    );
  }

  private _doUpdateUserPermissions(): void {
    this._sub = combineLatest([this._authState.getUserPermissions(), this._userEmail$])
      .pipe(flattenWithLatestFrom21())
      .subscribe((value: [ModuleScopes<boolean>, BrandType[], string]) =>
        this._userDatabase.updateUserPermissions(value)
      );
  }

  private _initLoadSportsGroupOnLogin(): void {
    this._sub = this._authState.isLoggedInSignal().subscribe(() => this._sportsGroupsLoader.init());
  }

  private _initBrandSettingsOnLogin(): void {
    this._sub = this._authState.isLoggedInSignal().subscribe(() => this._brandSettings.init());
  }

  private _handleLogout(): void {
    this._sub = this._doLogout$.subscribe(() => this._authState.logout());
    this._sub = this._isNotLoggedInSignal().subscribe(() => {
      const redirect: string = this._router.routerState.snapshot.url;
      this._router.navigate(['/login'], {
        queryParams: { redirect }
      });
    });
  }

  private _handleToggleTheme(): void {
    this._sub = this._doToggleTheme$
      .pipe(
        withLatestFrom(this._currentUrl(), this._getUserThemeSettings()),
        map(([theme, url, storedUserTheme]: [Theme, string, UserTheme]) => ({
          ...storedUserTheme,
          [url]: theme
        }))
      )
      .subscribe((value: UserTheme) => this._userDatabase.updateUserSettings({ uiTheme: value }));
  }

  private _handleToggleMenu(): void {
    this._sub = this._doToggleMenu$.subscribe((value: boolean) =>
      this._userDatabase.updateUserSettings({ isMenuExpanded: value })
    );
  }

  private _handleToggleFilters(): void {
    this._sub = this._doToggleFilters$.subscribe((value: boolean) =>
      this._userDatabase.updateUserSettings({ isFiltersExpanded: value })
    );
  }

  private _handleExpandSubMenu(): void {
    this._sub = this._doExpandSubMenu$.subscribe((value: Record<number, boolean>) =>
      this._userDatabase.updateUserSettings({ expandedSubMenus: value })
    );
  }

  /**
   * TODO display only favorite sports - need ticket
   * public displayOnlyFavoriteSports: boolean = false;
   * public doToggleFavoriteSportsVisible$: Subject<boolean> = new Subject<boolean>();
   *     this._sub = this._sharedSportsGroups.didRefreshDB$
      .pipe(
        startWith(undefined),
        switchMap(() => this._sharedSportsGroups.getDisplayOnlyFavoriteSports())
      )
      .subscribe(this._set('displayOnlyFavoriteSports'));

    this._sub = this.doToggleFavoriteSportsVisible$.subscribe((value: boolean) => {
      this._sportsGroupsLoader.updateFavoriteSportsVisibility(value);
      this._sportsGroupsLoader.didRefreshDB$.next(undefined);
    });
   */
}
