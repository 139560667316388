import { SessionStorageKeys } from '@sap/shared/enums';
import { NavigationItem } from '../left-navigation-drawer.component';

export const offerManagementMain: NavigationItem = {
  title: 'Offer Management',
  url: undefined,
  icon: 'color-border',
  scope: 'offerManagement.main'
};

export const offerManagementCreateEvent: NavigationItem = {
  title: 'Create Event',
  url: '/create-manual-event',
  icon: 'playlist-add',
  queryParams: { provider: 'm', limit: 20, sort: '-date' },
  scope: 'offerManagement.manualOfferEvent',
  sessionStorageKeyToRemoveOnClose: SessionStorageKeys.lastAddedManualEventSuccess
};

export const offerManagementCreateMarket: NavigationItem = {
  title: 'Create Market',
  url: '/create-manual-market',
  icon: 'playlist-add',
  scope: 'offerManagement.manualOfferMarket',
  sessionStorageKeyToRemoveOnClose: SessionStorageKeys.lastAddedManualMarketSuccess
};

export const offerManagementSupplyManager: NavigationItem = {
  title: 'Supply Manager',
  url: '/supply-manager',
  icon: 'input-power',
  scope: 'offerManagement.supplyManager'
};

export const offerManagementSupplyMatches: NavigationItem = {
  title: 'Supply Matches',
  icon: 'input-power',
  url: '/supply-matches',
  scope: 'offerManagement.matchesSupply'
};

export const offerManagementSportsGroupsRestrictions: NavigationItem = {
  title: 'Sports Groups Restrictions',
  url: '/sports-groups-restrictions',
  icon: 'wrap-text',
  scope: 'offerManagement.sportGroupsRestrictions'
};

export const offerManagementEventRestrictions: NavigationItem = {
  title: 'Event Restrictions',
  url: '/event-restrictions',
  icon: 'indent-decrease',
  scope: 'offerManagement.eventRestrictions'
};

export const allOfferManagementChildren: NavigationItem[] = [
  offerManagementCreateEvent,
  offerManagementCreateMarket,
  offerManagementSupplyManager,
  offerManagementSupplyMatches,
  offerManagementSportsGroupsRestrictions,
  offerManagementEventRestrictions
];
