import { ChangeDetectorRef } from '@angular/core';

export type SetAndDetect<T> = <K extends keyof T>(key: K) => <V extends T[K]>(value: V) => void;

export function setAndDetect<T>(obj: T, cdRef: ChangeDetectorRef): SetAndDetect<T> {
  cdRef.detach();
  return <K extends keyof T>(key: K) =>
    <V extends T[K]>(value: V): void => {
      obj[key] = value;
      cdRef.detectChanges();
    };
}
