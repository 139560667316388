import { RoleScope } from '@sap/shared/enums';

export class Role {
  constructor(
    public id: number,
    public readonly name: string,
    public readonly scopes: RoleScope[],
    public readonly allowedModules: string[]
  ) {}

  public static fromJson(json: Record<string, any>): Role {
    return new Role(json['role_id'], json['name'], json['scopes'], json['allowed_modules']);
  }

  public static fromJsonArray(jsons: Record<string, any>[]): Role[] {
    return jsons.map(Role.fromJson);
  }
}
