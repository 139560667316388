import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import * as R from 'rambdax';
import { NavigationItem } from '../left-navigation-drawer/left-navigation-drawer.component';

@Pipe({ name: 'isActiveTab', standalone: true })
export class IsActiveTabPipe implements PipeTransform {
  public transform(
    tabItem: NavigationItem,
    currentUrl: string | undefined,
    currentParams: Params | undefined
  ): boolean {
    if (!currentUrl) {
      return false;
    }
    return tabItem.url === currentUrl && R.equals(tabItem.queryParams, currentParams);
  }
}
