import { EventRestrictionKind } from '@sap/logic/shared/enums';
import { BrandType, BrandTypeWithoutDots } from '@sap/shared/enums';
import * as R from 'rambdax';

export type SportsGroupBrandsVisibility = {
  bc: BrandType[] | undefined;
  bg: BrandType[] | undefined;
  sc: BrandType[] | undefined;
  u: BrandType[] | undefined;
};

export type SportsGroupOrder = {
  [language: string]: {
    [brand: string]: number;
  };
};

export function mapToBrandTypeWithoutDots(brandType: string | BrandType): BrandTypeWithoutDots {
  return <BrandTypeWithoutDots>brandType.split('.').join('');
}

export class SportsGroup {
  constructor(
    public readonly id: number,
    public readonly parentId: number | null,
    public readonly name: string,
    public readonly brandsVisibility: SportsGroupBrandsVisibility,
    public readonly order: SportsGroupOrder,
    public readonly restriction: Record<BrandTypeWithoutDots, EventRestrictionKind> | undefined
  ) {}

  public static fromJsonArray(jsons: Record<string, any>[]): SportsGroup[] {
    return jsons.map(SportsGroup.fromJson);
  }

  public static fromJson(json: Record<string, any>): SportsGroup {
    return new SportsGroup(
      json['sports_group_id'],
      json['parent_id'],
      json['name'],
      json['brands_visibility'],
      json['order'],
      json['sports_group_restriction']
        ? R.mapKeys((brand: string) => mapToBrandTypeWithoutDots(brand), json['sports_group_restriction'])
        : undefined
    );
  }

  public static fromDb(trueJson: SportsGroup): SportsGroup {
    return new SportsGroup(
      trueJson.id,
      trueJson.parentId,
      trueJson.name,
      trueJson.brandsVisibility,
      trueJson.order,
      trueJson.restriction
    );
  }

  public static fromDbArray(trueJsons: SportsGroup[]): SportsGroup[] {
    return trueJsons.map(SportsGroup.fromDb);
  }
}
