import { BrandType } from '@sap/shared/enums';

export class User {
  constructor(
    public id: number,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly created: Date,
    public readonly brandsRoles: Record<BrandType, string[]>
  ) {}

  public static fromJson(json: Record<string, any>): User {
    return new User(
      json['user_id'],
      json['email'],
      json['first_name'],
      json['last_name'],
      new Date(json['created']),
      json['brands_roles']
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): User[] {
    return jsons.map(User.fromJson);
  }
}
