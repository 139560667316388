import { Injectable } from '@angular/core';
import { BrandInfo } from '@sap/logic/shared/models/brand-info';
import { Provider } from '@sap/logic/shared/models/provider';
import { InfoApi } from '../api/info-api';

@Injectable({
  providedIn: 'root'
})
export class InfoRepository {
  constructor(private _infoApi: InfoApi) {}

  public getBrands(): Promise<BrandInfo[]> {
    return this._infoApi.getBrands();
  }

  public getProviders(): Promise<Provider[]> {
    return this._infoApi.getProviders();
  }
}
