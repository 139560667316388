import { Market } from '@sap/logic/shared/models/market';
import { ProviderMarketType } from '@sap/logic/shared/models/provider-market-type';
import { SelectionInfo } from '@sap/logic/shared/models/selection-info';

export class NgForTracker {
  public byId<T extends { id: string | number }>(_index: number, data: T): string | number {
    return data.id;
  }

  public byMarketId(_index: number, market: Market): string {
    return market.id;
  }

  public bySelectionId(_index: number, selection: SelectionInfo): string {
    return selection.id;
  }

  public byMarketTypeId(_index: number, data: ProviderMarketType): string {
    return data.marketTypeId;
  }

  public byIndex = (index: number): number => {
    return index;
  };
}
