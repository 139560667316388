import { Injectable } from '@angular/core';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { FirebaseApp, initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseSettings {
  private _appCached?: FirebaseApp;

  constructor(private _settings: SharedSettings) {}

  public get instance(): FirebaseApp {
    return (this._appCached ??= initializeApp(this._settings.environment.firebaseConfig));
  }
}
