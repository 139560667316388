import * as R from 'rambdax';
import { SortMode } from '../enums';
import { isDefined, isStringAndNoEmpty } from './pure-utils';

export type ReturnSplittedDataByComparedValue<T> = {
  hasComparedValue: T[];
  others: T[];
};

export function groupDataByHasComparedValue<T, K extends keyof T>(
  data: T[],
  propString: K,
  valueToCompare: T[K]
): ReturnSplittedDataByComparedValue<T> {
  if (R.any((value: T) => value[propString] === valueToCompare)) {
    return R.piped(
      data,
      R.reduce(
        (acc: ReturnSplittedDataByComparedValue<T>, value: T) => {
          isDefined(value[propString]) && value[propString] === valueToCompare
            ? acc.hasComparedValue.push(value)
            : acc.others.push(value);
          return acc;
        },
        { hasComparedValue: [], others: [] }
      )
    );
  }
  return { hasComparedValue: [], others: data };
}

export function groupDataByHasValue<T, K extends keyof T>(data: T[], propString: K): { defined: T[]; undefined: T[] } {
  if (R.any((value: T) => isDefined(value[propString]))) {
    return R.piped(
      data,
      R.reduce(
        (acc: { defined: T[]; undefined: T[] }, value: T) => {
          isDefined(value[propString]) && value[propString] !== null
            ? acc.defined.push(value)
            : acc.undefined.push(value);
          return acc;
        },
        { defined: [], undefined: [] }
      )
    );
  }
  return { defined: [], undefined: data };
}

export function groupDataByArrayLength<T, K extends keyof T>(
  data: T[],
  propString: K
): { defined: T[]; undefined: T[] } {
  if (R.any((value: T) => isDefined(value[propString]))) {
    return R.piped(
      data,
      R.reduce(
        (acc: { defined: T[]; undefined: T[] }, value: T) => {
          isDefined(value[propString]) && value[propString] !== null && (value[propString] as []).length
            ? acc.defined.push(value)
            : acc.undefined.push(value);
          return acc;
        },
        { defined: [], undefined: [] }
      )
    );
  }
  return { defined: [], undefined: data };
}

export function groupDataByPathHasValue<T, K extends string[]>(data: T[], path: K): { defined: T[]; undefined: T[] } {
  if (R.any((value: T) => isStringAndNoEmpty(R.path(path, value)))) {
    return R.piped(
      data,
      R.reduce(
        (acc: { defined: T[]; undefined: T[] }, value: T) => {
          isStringAndNoEmpty(R.path(path, value)) ? acc.defined.push(value) : acc.undefined.push(value);
          return acc;
        },
        { defined: [], undefined: [] }
      )
    );
  }
  return { defined: [], undefined: data };
}

export function sortDataByNumberProp<T>(data: T[], mode: SortMode, prop: keyof T, reverse: boolean = false): T[] {
  const propString: string = prop.toString();
  const groupedDataByHasValueToSort: {
    defined: T[];
    undefined: T[];
  } = groupDataByHasValue(data, prop);

  if (mode === SortMode.asc) {
    const sorted: T[] = R.piped(
      groupedDataByHasValueToSort.defined,
      R.sort((a: T, b: T) => (isDefined(a[propString]) ? a[propString] - b[propString] : a[propString]))
    );
    return [...sorted, ...groupedDataByHasValueToSort.undefined];
  }

  const sortedDefinedByDesc: T[] = R.piped(
    groupedDataByHasValueToSort.defined,
    R.sort((itemA: T, itemB: T) =>
      isDefined(itemB[propString]) ? itemB[propString] - itemA[propString] : itemB[propString]
    )
  );

  return reverse
    ? [...groupedDataByHasValueToSort.undefined, ...sortedDefinedByDesc]
    : [...sortedDefinedByDesc, ...groupedDataByHasValueToSort.undefined];
}

export function sortDataByArrayLength<T>(data: T[], mode: SortMode, prop: keyof T): T[] {
  const propString: string = prop.toString();
  const groupedDataByHasValueToSort: {
    defined: T[];
    undefined: T[];
  } = groupDataByArrayLength(data, prop);

  if (mode === SortMode.asc) {
    const sortedDefinedByAsc: T[] = R.piped(
      groupedDataByHasValueToSort.defined,
      R.sort((itemA: T, itemB: T) => itemA[propString].length - itemB[propString].length)
    );
    return [...sortedDefinedByAsc, ...groupedDataByHasValueToSort.undefined];
  }
  const sortedDefinedByDesc: T[] = R.piped(
    groupedDataByHasValueToSort.defined,
    R.sort((itemA: T, itemB: T) => itemB[propString].length - itemA[propString].length)
  );
  return [...sortedDefinedByDesc, ...groupedDataByHasValueToSort.undefined];
}

export function sortDataByValuesInArray<T>(data: T[], mode: SortMode, prop: keyof T): T[] {
  const propString: string = prop.toString();
  const groupedDataByHasValueToSort: {
    defined: T[];
    undefined: T[];
  } = groupDataByArrayLength(data, prop);
  if (mode === SortMode.asc) {
    const sortedDefinedByAsc: T[] = R.piped(
      groupedDataByHasValueToSort.defined,
      R.sort((itemA: T, itemB: T) => itemA[propString].join(' ').localeCompare(itemB[propString].join(' ')))
    );
    return [...sortedDefinedByAsc, ...groupedDataByHasValueToSort.undefined];
  }
  const sortedDefinedByDesc: T[] = R.piped(
    groupedDataByHasValueToSort.defined,
    R.sort((itemA: T, itemB: T) => itemB[propString].join(' ').localeCompare(itemA[propString].join(' ')))
  );
  return [...sortedDefinedByDesc, ...groupedDataByHasValueToSort.undefined];
}

export function sortDataByStringProp<T>(data: T[], mode: SortMode, prop: keyof T): T[] {
  const propString: string = prop.toString();
  const groupedDataByHasValueToSort: {
    defined: T[];
    undefined: T[];
  } = groupDataByHasValue(data, prop);

  if (mode === SortMode.asc) {
    const sortedDefinedByAsc: T[] = R.piped(
      groupedDataByHasValueToSort.defined,
      R.sort((itemA: T, itemB: T) =>
        itemA[propString] !== null ? itemA[propString].localeCompare(itemB[propString]) : itemA[propString]
      )
    );
    return [...sortedDefinedByAsc, ...groupedDataByHasValueToSort.undefined];
  }
  const sortedDefinedByDesc: T[] = R.piped(
    groupedDataByHasValueToSort.defined,
    R.sort((itemA: T, itemB: T) =>
      itemB[propString] ? itemB[propString].localeCompare(itemA[propString]) : itemB[propString]
    )
  );
  return [...sortedDefinedByDesc, ...groupedDataByHasValueToSort.undefined];
}

export function sortDataByDate<T>(data: T[], mode: SortMode, prop: keyof T): T[] {
  const propString: string = prop.toString();
  const groupedDataByHasValueToSort: {
    defined: T[];
    undefined: T[];
  } = groupDataByHasValue(data, prop);

  if (mode === SortMode.asc) {
    const sortedDefinedByAsc: T[] = R.piped(
      groupedDataByHasValueToSort.defined,
      R.sort((a: T, b: T) => a[propString].getTime() - b[propString].getTime())
    );
    return [...sortedDefinedByAsc, ...groupedDataByHasValueToSort.undefined];
  }
  const sortedDefinedByDesc: T[] = R.piped(
    groupedDataByHasValueToSort.defined,
    R.sort((a: T, b: T) => b[propString].getTime() - a[propString].getTime())
  );
  return [...sortedDefinedByDesc, ...groupedDataByHasValueToSort.undefined];
}
