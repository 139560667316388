import { EventEmitter } from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
  MonoTypeOperatorFunction,
  Observable,
  ReplaySubject,
  Subject,
  timer
} from 'rxjs';
import { delayWhen, take, tap } from 'rxjs/operators';

export function transferRxNext<T>(stream$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>) {
  return (value: T): void => stream$.next(value);
}

export function transferRxNextVoid(stream$: Subject<void> | BehaviorSubject<void> | ReplaySubject<void>) {
  return (): void => stream$.next(undefined);
}

export function transferEmitter<T>(emitter: EventEmitter<T>) {
  return (value: T): void => emitter.emit(value);
}

export function rxDone(stream$: Subject<void>): void {
  stream$.next();
  stream$.complete();
}

export function asPromise<T>(stream$: Observable<T>): Promise<T> {
  return lastValueFrom(stream$.pipe(take(1)));
}

export function streamComplete<T>(stream$: Subject<T | undefined>): void {
  stream$.next(undefined);
  stream$.complete();
}

export function tapTransferToEmitter<T>(emitter: EventEmitter<T>): MonoTypeOperatorFunction<T> {
  return tap((value: T) => emitter.emit(value));
}

export function tapTransferRxNext<T>(
  stream$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>
): MonoTypeOperatorFunction<T> {
  return tap((value: T) => stream$.next(value));
}

export function tapTransferRxNextVoid(
  stream$: Subject<void> | BehaviorSubject<void> | ReplaySubject<void>
): MonoTypeOperatorFunction<void> {
  return tap(() => stream$.next(undefined));
}

export function nextTick<T>(): MonoTypeOperatorFunction<T> {
  const duration: Observable<0> = timer(0);
  return delayWhen(() => duration);
}
