import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem } from '../left-navigation-drawer.component';

@Pipe({ name: 'isActiveNavItem', standalone: true })
export class IsActiveNavItemPipe implements PipeTransform {
  public transform(navItemChildren: NavigationItem[], currentUrl: string | undefined): boolean {
    if (!currentUrl) {
      return false;
    }
    return navItemChildren.some((item: NavigationItem) => (item.url ? currentUrl.includes(item.url) : false));
  }
}
