import { BrandType } from '@sap/shared/enums';
import { SportsGroup, SportsGroupBrandsVisibility } from '../models/sports-group';

export function mapToSportsGroupsWithoutUnassigned(
  sportsGroups: SportsGroup[],
  isNegative: boolean = false
): SportsGroup[] {
  return sportsGroups.filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/typedef
    ({ brandsVisibility: { u: unassigned, ...otherProviders } }: { brandsVisibility: SportsGroupBrandsVisibility }) => {
      const hasSomeBrandVisibility: boolean = Object.values(otherProviders).some(
        (brandVisibility: BrandType[] | undefined) => Boolean(brandVisibility?.length)
      );
      return isNegative ? !hasSomeBrandVisibility : hasSomeBrandVisibility;
    }
  );
}
