import { NavigationItem } from '../left-navigation-drawer.component';
import { brandsWithoutDefault } from './brand';

export const settingsMain: NavigationItem = {
  title: 'Settings',
  url: undefined,
  icon: 'settings',
  scope: 'settings.main'
};

export const settingsAgents: NavigationItem = {
  title: 'Agents',
  url: '/agents',
  icon: 'address-book',
  scope: 'settings.agents',

  brandsScope: brandsWithoutDefault
};

export const settingsLocations: NavigationItem = {
  title: 'Locations',
  url: '/locations',
  icon: 'location-pin',
  scope: 'settings.locations',

  brandsScope: brandsWithoutDefault
};

export const settingsTerminals: NavigationItem = {
  title: 'Terminals',
  url: '/terminals',
  icon: 'terminal',
  scope: 'settings.terminals',

  brandsScope: brandsWithoutDefault
};

export const settingsCountryLimits: NavigationItem = {
  title: 'Country Limits',
  url: '/country-limits',
  icon: 'speedometer',
  scope: 'settings.countryLimits',

  brandsScope: brandsWithoutDefault
};

export const allSettingsChildren: NavigationItem[] = [
  settingsAgents,
  settingsLocations,
  settingsTerminals,
  settingsCountryLimits
];
