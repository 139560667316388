import { HttpErrorResponse } from '@angular/common/http';
import { of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function predicateNotFoundError<T>(result: T | MessageError): boolean {
  return !(result instanceof MessageError);
}

export class MessageError {
  constructor(
    public readonly message: string | undefined,
    public readonly status?: string | undefined
  ) {}

  public static fromHttpError<T>(): OperatorFunction<T, MessageError | T> {
    return catchError((err: HttpErrorResponse) => {
      const errorMessage: string = Array.isArray(err.error.detail)
        ? err.error.detail[0].msg
        : err.error.title && !err.error.detail
        ? err.error.title
        : err.error.detail ?? err.error.message;

      // 403 errors are handling by apiForbiddenDetector
      return err.status !== 403 ? of(new MessageError(errorMessage)) : of(new MessageError(undefined));
    });
  }
}
