import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export const withoutFirstWithLatestFrom1 = <O1, O2>(): OperatorFunction<[O1, O2], [O2]> =>
  map((array: [O1, O2]) => [array[1]]);

export const withoutFirstWithLatestFrom2 = <O1, O2, O3>(): OperatorFunction<[O1, O2, O3], [O2, O3]> =>
  map((array: [O1, O2, O3]) => [array[1], array[2]]);

export const flattenWithLatestFrom21 = <O1, O2, O3>(): OperatorFunction<[[O1, O2], O3], [O1, O2, O3]> =>
  map((array: [[O1, O2], O3]) => [...array[0], array[1]]);

export const withoutFirstWithLatestFrom3 = <O1, O2, O3, O4>(): OperatorFunction<[O1, O2, O3, O4], [O2, O3, O4]> =>
  map((array: [O1, O2, O3, O4]) => [array[1], array[2], array[3]]);

export const withoutFirstWithLatestFrom4 = <O1, O2, O3, O4, O5>(): OperatorFunction<
  [O1, O2, O3, O4, O5],
  [O2, O3, O4, O5]
> => map((array: [O1, O2, O3, O4, O5]) => [array[1], array[2], array[3], array[4]]);

export const concatFirstAndSecondFrom3 = <T>(): OperatorFunction<[T[], T[], T[]], [T[], T[]]> =>
  map((array: [T[], T[], T[]]) => [[...array[0], ...array[1]], array[2]]);
