import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MiniAuthInfoBloc } from '@sap/logic/auth/bloc/mini-auth-info/mini-auth-info.bloc';
import { UserAuthState } from '@sap/logic/auth/enums';
import { asPromise } from '@sap/shared/helpers/rx-helpers';
import { filterNavigationEnd } from '@sap/ui/shared/rxjs/operators';

@Component({
  selector: 'sap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MiniAuthInfoBloc]
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    private _cdRef: ChangeDetectorRef,
    private _miniAuthInfoBloc: MiniAuthInfoBloc
  ) {}

  public async ngOnInit(): Promise<void> {
    // need this when we not use Zone
    this._router.events.pipe(filterNavigationEnd).subscribe(() => {
      // detectChanges(this);
      this._cdRef.detectChanges();
    });
    const state: UserAuthState = await asPromise(this._miniAuthInfoBloc.getAuthStateChecked());
    if (state === UserAuthState.isNotLoggedIn) {
      this._miniAuthInfoBloc.signOutFirebase();
      const redirect: string = this._router.routerState.snapshot.url;
      this._router.navigate(['/login'], {
        queryParams: redirect.startsWith('/login') ? null : { redirect }
      });
    }
  }
}
