import { Injectable } from '@angular/core';
import { Auth, getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QuerySnapshot,
  Unsubscribe,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { Observable, ReplaySubject, Subscriber, distinctUntilChanged, filter, mapTo, mergeMap, pluck } from 'rxjs';
import { FirebaseSettings } from '../firebase/firebase-settings';

@Injectable({ providedIn: 'root' })
export class FirestoreDatabase {
  private _isUserSigned$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _userEmailSigned: string | undefined;
  private _firestore?: Firestore;

  constructor(private _firebaseSettings: FirebaseSettings) {}

  public get userEmailSigned(): string | undefined {
    return this._userEmailSigned;
  }

  public get _db(): Firestore {
    return (this._firestore ??= getFirestore(this._firebaseSettings.instance));
  }

  public getIsUserSigned(): Observable<boolean> {
    return this._isUserSigned$;
  }

  public userSignOutSignal(): Observable<void> {
    return this._isUserSigned$.pipe(
      distinctUntilChanged(),
      filter((state: boolean) => state === false),
      mapTo(undefined)
    );
  }

  public getData(collectionName: string, primaryKey: string): Observable<Record<string, any>> {
    return this._getData(collectionName, primaryKey).pipe(filter(Boolean));
  }

  public getObjectData(
    collectionName: string,
    objectName: string,
    primaryKey: string
  ): Observable<Record<string, any>> {
    return this._getData(collectionName, primaryKey).pipe(pluck(objectName), filter(Boolean));
  }

  public getArrayData(
    collectionName: string,
    arrayName: string,
    primaryKey: string
  ): Observable<Record<string, any>[]> {
    return this._getData(collectionName, primaryKey).pipe(pluck(arrayName), filter(Boolean));
  }

  public async getCollection(collectionName: string): Promise<QuerySnapshot<DocumentData, DocumentData>> {
    return await getDocs(collection(this._db, collectionName));
  }

  public async getCollectionWithArrayField(
    collectionName: string,
    arrayFieldName: string
  ): Promise<Record<string, DocumentData[]>> {
    const toReturn: Record<string, DocumentData[]> = {};
    const querySnapshot: QuerySnapshot<DocumentData, DocumentData> = await this.getCollection(collectionName);
    querySnapshot.forEach((doc: DocumentData) => (toReturn[doc.id] = doc.data()[arrayFieldName]));
    return toReturn;
  }

  public async setDataInArray(
    collectionName: string,
    primaryKey: string,
    arrayName: string,
    payload: Record<string, any>
  ): Promise<void> {
    const docRef: DocumentReference<DocumentData> = doc(this._db, collectionName, primaryKey);
    const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(doc(this._db, collectionName, primaryKey), {
        [arrayName]: arrayUnion(payload)
      });
    } else {
      await setDoc(doc(this._db, collectionName, primaryKey), {
        [arrayName]: arrayUnion(payload)
      });
    }
  }

  public async setData(
    collectionName: string,
    primaryKey: string,
    fieldName: string,
    payload: Record<string, any>
  ): Promise<void> {
    const docRef: DocumentReference<DocumentData> = doc(this._db, collectionName, primaryKey);
    const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);
    const payloadWithFieldPaths: Record<string, any> = {};
    for (const key in payload) {
      payloadWithFieldPaths[`${fieldName}.${key}`] = payload[key];
    }
    if (docSnap.exists()) {
      await updateDoc(doc(this._db, collectionName, primaryKey), payloadWithFieldPaths);
    } else {
      await setDoc(doc(this._db, collectionName, primaryKey), payloadWithFieldPaths);
    }
  }

  public async signInToFirebaseWithCustomToken(accessToken: string, userEmail: string): Promise<void> {
    const auth: Auth = getAuth(this._firebaseSettings.instance);
    this._userEmailSigned = userEmail;
    await signInWithCustomToken(auth, accessToken).then(() => this._isUserSigned$.next(true));
  }

  public async signOutFirebase(): Promise<void> {
    const auth: Auth = getAuth(this._firebaseSettings.instance);
    this._userEmailSigned = undefined;
    await signOut(auth).then(() => this._isUserSigned$.next(false));
  }

  private _getData(collectionName: string, primaryKey: string): Observable<Record<string, any>> {
    return this._isUserSigned$.pipe(
      filter(Boolean),
      mergeMap(
        () =>
          new Observable((subscriber: Subscriber<Record<string, any>>) => {
            const unsubFn: Unsubscribe = onSnapshot(
              doc(this._db, collectionName, primaryKey),
              (doc: DocumentSnapshot<DocumentData>) => {
                return subscriber.next(doc.data());
              }
            );
            return (): void => unsubFn();
          })
      )
    );
  }
}
