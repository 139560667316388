import { Injectable } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { clear, createStore, get, getMany, set, setMany, UseStore, values } from 'idb-keyval';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IndexdbStorage {
  private _didUpdate$: Subject<void> = new Subject<void>();

  constructor(private _settings: SharedSettings) {}

  public async createStore(storeName: string): Promise<UseStore> {
    return createStore(this._getIdbName, storeName);
  }

  public async set<T>(key: IDBValidKey, value: T, store?: UseStore): Promise<void> {
    await set(key, value, store);
    this._didUpdate$.next(undefined);
  }

  public get<T>(key: IDBValidKey, store?: UseStore): Promise<T | undefined> {
    return get(key, store);
  }

  public async getMany<T>(keys: IDBValidKey[], store?: UseStore): Promise<T[]> {
    const results: (T | undefined)[] = await getMany(keys, store);
    return results.filter(isDefined);
  }

  public getAllValues<T>(store?: UseStore): Promise<T[]> {
    return values(store);
  }

  public async setMany<T>(entries: [IDBValidKey, T][], store?: UseStore): Promise<void> {
    await setMany(entries, store);
    this._didUpdate$.next(undefined);
  }

  public async clear(store?: UseStore): Promise<void> {
    store ? await clear(store) : await clear();
    this._didUpdate$.next(undefined);
  }

  public didUpdated(): Observable<void> {
    return this._didUpdate$.asObservable();
  }

  private get _getIdbName(): string {
    const prodDevKind: string = this._settings.environment.production ? 'prod' : 'dev';
    return `sap_idb_${prodDevKind}`;
  }
}
