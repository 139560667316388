<ng-container *ngIf="toastMessageSuccess">
  <div class="toast toast-success">
    <div class="toast-info">
      <span *ngIf="toastMessageSuccess.status" class="toast-status">
        {{ toastMessageSuccess.status }} <span *ngIf="toastCount > 1">({{ toastCount }})</span>
      </span>
      <span class="toast-message">{{ toastMessageSuccess.message }}</span>
    </div>
    <span class="toast-button toast-close" (click)="doClose()">x</span>
  </div>
</ng-container>
<ng-container *ngIf="toastMessageError">
  <div class="toast toast-danger">
    <span *ngIf="toastMessageError.status" class="toast-status">
      {{ toastMessageError.status }} <span *ngIf="toastErrorCount > 1">({{ toastErrorCount }})</span>
    </span>
    <div class="toast-info">
      <span class="toast-message">{{ toastMessageError.message }}</span>
    </div>
    <span class="toast-button toast-close" (click)="doCloseError()">x</span>
  </div>
</ng-container>
