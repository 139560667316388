import { ReversedMatchOfferProvider } from '@sap/shared/enums';

export class Provider {
  constructor(
    public readonly id: string,
    public readonly name: string
  ) {}

  public static fromJson(json: Record<string, any>): Provider {
    return new Provider(json['name'], ReversedMatchOfferProvider[json['name']] ?? json['description']);
  }

  public static fromJsonArray(jsons: Record<string, any>[]): Provider[] {
    return jsons.map(Provider.fromJson);
  }
}
