export const moduleScopes: Record<string, Record<string, string[]>> = {
  crm: {
    lowerTaxSearch: ['offer_mgr:lower_taxes_search:get', 'offer_mgr:lower_taxes:get'],
    lowerTaxCreate: ['offer_mgr:lower_taxes:post'],
    rateBoostSearch: ['offer_mgr:rate_boosts_search:get', 'offer_mgr:rate_boosts:get'],
    rateBoostCreate: ['offer_mgr:rate_boosts:post'],
    apiTokens: ['offer_mgr:api_tokens_search:get'],
    main: [
      'offer_mgr:lower_taxes_search:get',
      'offer_mgr:lower_taxes:get',
      'offer_mgr:lower_taxes:post',
      'offer_mgr:rate_boosts_search:get',
      'offer_mgr:rate_boosts:get',
      'offer_mgr:rate_boosts:post',
      'offer_mgr:api_tokens_search:get'
    ]
  },
  content: {
    sportGroupsSorting: [
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_children:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get'
    ],
    competitionMapping: ['offer_mgr:competitions_search:get'],
    primaryColumns: ['offer_mgr:primary_columns_search:get'],
    marketGroups: ['offer_mgr:markets_groups_search:get'],
    translations: [
      'translation_mgr:translation_search:get',
      'translation_mgr:translation_types:get',
      'translation_mgr:languages:get'
    ],
    recommendedStandard: ['offer_api:recommendations_search:get'],
    recommendedEsports: ['offer_api:recommendations_esports_search:get'],
    main: [
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_children:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get',
      'offer_mgr:competitions_search:get',
      'offer_mgr:primary_columns_search:get',
      'offer_mgr:markets_groups_search:get',
      'translation_mgr:translation_search:get',
      'translation_mgr:translation_types:get',
      'translation_mgr:languages:get',
      'offer_api:recommendations_search:get',
      'offer_api:recommendations_esports_search:get'
    ]
  },
  offerManagement: {
    matchesSupply: ['offer_api:matches:get', 'offer_api:matches_search:get'],
    manualOfferEvent: ['manual_api:event:post'],
    manualOfferMarket: ['manual_api:market:post'],
    supplyManager: [
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get'
    ],
    sportGroupsRestrictions: [
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get'
    ],
    eventRestrictions: [
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get'
    ],
    main: [
      'offer_api:matches:get',
      'offer_api:matches_search:get',
      'manual_api:event:post',
      'manual_api:market:post',
      'offer_mgr:brands_search:get',
      'offer_mgr:sports_groups_count:get',
      'offer_mgr:sports_groups_providers_search:get',
      'offer_mgr:sports_groups_search:get'
    ]
  },
  riskManagement: {
    marketClassesSetup: ['offer_mgr:markets_classes_search:get', 'offer_mgr:market_types_search:get'],
    profileCreate: ['offer_mgr:profiles_to_sports_groups_search:get', 'offer_mgr:profiles_search:get'],
    profileEdit: ['offer_mgr:profiles_market_classes_search:get'],
    resultingManager: [
      'offer_api:markets_search:get',
      'offer_api:matches:get',
      'offer_api:matches_search:get',
      'offer_api:markets:patch'
    ],
    maintenance: ['client_bo_api:maintenance_mode:get'],
    main: [
      'offer_mgr:markets_classes_search:get',
      'offer_mgr:market_types_search:get',
      'offer_mgr:profiles_to_sports_groups_search:get',
      'offer_mgr:profiles_search:get',
      'offer_mgr:profiles:delete',
      'offer_mgr:profiles_market_classes:put',
      'offer_api:markets_search:get',
      'offer_api:matches:get',
      'offer_api:matches_search:get',
      'offer_api:markets:patch',
      'client_bo_api:maintenance_mode:get'
    ]
  },
  customers: {
    main: ['client_bo_api:clients_search:get', 'client_bo_api:clients:get']
  },
  betTracer: {
    main: ['client_bo_api:betslips_search:get']
  },
  matchesList: {
    main: ['offer_api:matches:get', 'offer_api:matches_search:get']
  },
  externalProducts: {
    streamings: ['offer_api:streamings_search:get'],
    streamMapping: ['offer_api:streamings_search:get'],
    sportcastMapping: ['offer_api:matches_search:get', 'offer_api:matches:get'],
    main: ['offer_api:streamings_search:get', 'offer_api:matches_search:get', 'offer_api:matches:get']
  },
  users: {
    list: ['auth:users:get', 'auth:users_search:get'],
    create: ['auth:users:post'],
    resetPassword: ['auth:users_reset_password:put'],
    password: ['auth:users_change_password:put'],
    roles: ['auth:roles:get'],
    main: [
      'auth:users:get',
      'auth:users_search:get',
      'auth:users:post',
      'auth:users_change_password:put',
      'auth:roles:get'
    ]
  },
  eventView: {
    main: ['offer_api:market_risk_search:get']
  },
  settings: {
    agents: ['client_bo_api:agents_search:get'],
    terminals: ['client_bo_api:terminals_search:get'],
    locations: ['client_bo_api:locations_search:get'],
    countryLimits: ['client_bo_api:country_limits_search:get'],
    main: ['client_bo_api:locations_search:get, client_bo_api:country_limits_search:get']
  }
};
