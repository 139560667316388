import { Event, NavigationEnd } from '@angular/router';
import { MonoTypeOperatorFunction, Observable, Observer, Subscriber } from 'rxjs';
import { filter } from 'rxjs/operators';

export const filterNavigationEnd: MonoTypeOperatorFunction<Event> = filter(
  (event: Event) => event instanceof NavigationEnd
);

export const isElementVisibleOnScreen = (
  element: HTMLElement,
  options?: IntersectionObserverInit
): Observable<boolean> => {
  return new Observable((subscriber: Subscriber<boolean>) => {
    const observerIntersection: IntersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => subscriber.next(entries[entries.length - 1].isIntersecting),
      options
    );
    observerIntersection.observe(element);
    return (): void => observerIntersection.disconnect();
  });
};

export function browserIdle(): Observable<void> {
  const windowAsAny: any = window;
  return new Observable<void>((observer: Observer<void>) => {
    const handle: any = windowAsAny.requestIdleCallback(() => {
      observer.next();
    });
    return (): void => windowAsAny.cancelIdleCallback(handle);
  });
}
