import { Injectable } from '@angular/core';
import { UserDatabaseSettings } from '@sap/logic/shared/models/user-database-settings';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { BrandType } from '@sap/shared/enums';
import { filter, map, Observable, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDatabase {
  constructor(private _firestoreDatabase: FirestoreDatabase) {}

  public getIsUserSignedToFirestore(): Observable<boolean> {
    return this._firestoreDatabase.getIsUserSigned().pipe(
      tap((userSigned: boolean) => console.logDev('firestore userSigned', userSigned)),
      filter(Boolean)
    );
  }

  public getUserSettings(): Observable<UserDatabaseSettings> {
    return this._firestoreDatabase
      .getObjectData('users', 'settings', this._firestoreDatabase.userEmailSigned!)
      .pipe(takeUntil(this._firestoreDatabase.userSignOutSignal()), map(UserDatabaseSettings.fromJson));
  }

  public async updateUserSettings(payload: Record<string, any>): Promise<void> {
    if (this._firestoreDatabase.userEmailSigned) {
      await this._firestoreDatabase.setData('users', this._firestoreDatabase.userEmailSigned, 'settings', payload);
    }
  }

  public async updateUserPermissions([modulePermissions, brandsFromScopes, userEmail]: [
    Record<string, Record<string, boolean>> | null,
    BrandType[] | null,
    string
  ]): Promise<void> {
    await this._firestoreDatabase.setData('users', userEmail, 'settings', {
      permissions: modulePermissions,
      brandsFromScopes
    });
  }

  public async cleanUserPermissions(userEmail: string): Promise<void> {
    await this._firestoreDatabase.setData('users', userEmail, 'settings', {
      permissions: null,
      brandsFromScopes: null
    });
  }
}
