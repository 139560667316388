export enum MatchUpdateKind {
  date = 'date',
  eventRestriction = 'event_restriction',
  isActive = 'is_active',
  isLive = 'is_live',
  isPre = 'is_pre',
  profileId = 'profile_id',
  status = 'status'
}

export enum EventRestrictionKind {
  noRestriction = 'no_restriction',
  onlyWithSameEvent = 'only_with_same_event',
  notWithSameParticipant = 'not_with_same_participant',
  notWithSameEvent = 'not_with_same_event',
  notWithSameCompetition = 'not_with_same_competition',
  notWithSameRegion = 'not_with_same_region',
  doNotCombine = 'do_not_combine'
}

export enum SortBy {
  id
}

export enum SortMode {
  asc,
  desc
}

export enum ReversedEnumStreamProvider {
  bc = 'betConstruct',
  bg = 'betGenius',
  br = 'betRadar'
}

export enum StreamProvider {
  betConstruct = 'bc',
  betGenius = 'bg',
  betRadar = 'br'
}

export enum StreamingProvider {
  betConstruct = 'bc',
  betGenius = 'bg',
  betRadar = 'br',
  twitch = 'twitch',
  youtube = 'youtube'
}

export enum MatchStatus {
  open = 'open',
  suspended = 'suspended',
  closed = 'closed'
}

export enum ClassificationPropertiesReadable {
  red = 'Red',
  reviewDown = 'Review Down',
  toCheck = 'To Check',
  new = 'New',
  gold = 'Gold',
  superVip = 'Super VIP',
  normal = 'Normal',
  reviewUp = 'Review Up',
  retailShop = 'Retail Shop',
  bonusAbuser = 'Bonus Abuser',
  terminal = 'Terminal'
}

export enum ClassificationValuesReadable {
  'big_loser' = 'Gold',
  'dangerous' = 'Red',
  'super_vip' = 'Super VIP',
  'review_up' = 'Review Up',
  'review_down' = 'Review Down',
  'to_check' = 'To Check',
  'bonus_abuser' = 'Bonus Abuser',
  'retail_shop' = 'Retail Shop',
  'new' = 'New',
  'normal' = 'Normal',
  'terminal' = 'Terminal'
}
