import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IconName } from '../icon/icon-types';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'sapds-button',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: `
    <sapds-icon *ngIf="iconName" [iconName]="iconName!" size="x-small" />
    <ng-content></ng-content>
  `,
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ButtonComponent {
  @Input() variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'transparent' = 'primary';
  @Input() size?: 'large' | 'small' | 'fullwidth';
  @Input() isLoading: boolean = false;
  @Input() isActive: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() iconName?: IconName;

  @HostBinding('class') public get cssClass(): string {
    const cssClass: (string | undefined)[] = [
      this.isActive ? 'is-active' : undefined,
      this.isLoading ? 'is-loading' : undefined,
      this.isDisabled ? 'is-disabled' : undefined
    ].filter(Boolean);

    if (this.size) {
      return ['button', `button_${this.variant}`, `button_${this.size}`, ...cssClass].join(' ');
    }
    return ['button', `button_${this.variant}`, ...cssClass].join(' ');
  }
}
