import { NavigationItem } from '../left-navigation-drawer.component';

export const usersMain: NavigationItem = {
  title: 'Users',
  url: undefined,
  icon: 'user',
  scope: 'users.main'
};

export const usersChangePassword: NavigationItem = {
  title: 'Change Password',
  url: '/users/change-password',
  icon: 'lock-locked',
  scope: 'users.password'
};

export const usersList: NavigationItem = {
  title: 'Users List',
  url: '/users-list',
  icon: 'people',
  scope: 'users.list'
};

export const usersCreateUser: NavigationItem = {
  title: 'Create User',
  url: '/users/create-user',
  icon: 'user-plus',
  scope: 'users.create'
};

export const usersPermissions: NavigationItem = {
  title: 'Permissions',
  url: '/users/permissions',
  icon: 'https',
  scope: 'users.roles'
};

export const usersSettings: NavigationItem = {
  title: 'User Settings',
  url: '/settings',
  icon: 'settings',
  scope: 'users.password'
};

export const allUsersChildren: NavigationItem[] = [
  usersChangePassword,
  usersList,
  usersCreateUser,
  usersPermissions
  // usersSettings
];
