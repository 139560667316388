import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem } from '../left-navigation-drawer.component';
import { betTracerMain } from '../navigation-items/bet-tracer';

@Pipe({ name: 'navRouterLink', standalone: true })
export class NavRouterLinkPipe implements PipeTransform {
  public transform(navItem: NavigationItem, indexTab: number): string[] | undefined {
    if (navItem.url?.startsWith(betTracerMain.url!) && indexTab) {
      return [navItem.url, indexTab.toString()];
    }
    return navItem.url ? [navItem.url] : undefined;
  }
}
