import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@sap/logic/shared/utils/pure-utils';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'utcTimer',
  pure: false
})
export class UtcTimerPipe implements PipeTransform, OnDestroy {
  private _sub: Subscription | undefined = undefined;
  private _valueToReturn: string | undefined;

  public transform(_val: undefined): string | undefined {
    if (isDefined(this._sub)) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }

    if (!isDefined(this._sub)) {
      this._subscribeUtcTime();
    }

    return this._valueToReturn;
  }

  constructor(private _cdRef: ChangeDetectorRef) {}

  private _subscribeUtcTime(): void {
    this._sub = timer(0, 1000)
      .pipe(map(() => new Date().toISOString().slice(11, 19)))
      .subscribe((time: string) => {
        this._valueToReturn = time;
        this._cdRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    if (isDefined(this._sub)) {
      this._sub.unsubscribe();
    }
  }
}
