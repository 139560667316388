import { Injectable } from '@angular/core';
import { EventRestrictionKind } from '@sap/logic/shared/enums';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { SportGroupsOrderPayload } from '@sap/logic/sports-groups-sorting/bloc/sports-groups-sorting/sports-groups-order-form';
import { BrandType } from '@sap/shared/enums';
import { SportsGroupApi, SportsGroupRestrictionResponseWithBrand } from '../api/sports-group-api';
import { MessageError } from '../errors/message-error';

@Injectable({ providedIn: 'root' })
export class SportsGroupRepository {
  constructor(private _sportsGroupApi: SportsGroupApi) {}

  public getSportsGroups(offset: number, limit: number): Promise<SportsGroup[]> {
    return this._sportsGroupApi.getSportsGroups(offset, limit);
  }

  public getSportsGroupsByParentId(parentId: number): Promise<SportsGroup[]> {
    return this._sportsGroupApi.getSportsGroupsByParentId(parentId);
  }

  public getSportsGroupsCount(): Promise<number> {
    return this._sportsGroupApi.getSportsGroupsCount();
  }

  public updateSportsGroupsBrandVisibility(
    sportsGroup: SportsGroup,
    brand: BrandType
  ): Promise<SportsGroup | MessageError> {
    return this._sportsGroupApi.updateSportsGroupsBrandVisibility(sportsGroup, brand);
  }

  public updateSportsGroupOrder(payload: SportGroupsOrderPayload): Promise<SportsGroup | MessageError> {
    return this._sportsGroupApi.updateSportsGroupOrder(payload);
  }

  public updateSportsGroupRestriction(
    sportsGroup: SportsGroup,
    brand: BrandType,
    restriction: EventRestrictionKind
  ): Promise<SportsGroupRestrictionResponseWithBrand | MessageError> {
    return this._sportsGroupApi.updateSportsGroupRestriction(sportsGroup, brand, restriction);
  }

  public addSportGroup(payload: Record<string, any>): Promise<SportsGroup | MessageError> {
    return this._sportsGroupApi.addSportGroup(payload);
  }

  public removeSportGroup(id: number): Promise<SportsGroup | MessageError> {
    return this._sportsGroupApi.removeSportGroup(id);
  }
}
