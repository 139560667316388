import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAuthState } from '../../enums';
import { AuthState } from '../../services/auth-state';

@Injectable()
export class MiniAuthInfoBloc {
  constructor(private _authState: AuthState) {}

  public getAuthStateChecked(): Observable<UserAuthState> {
    return this._authState.getAuthStateChecked();
  }

  public signOutFirebase(): void {
    this._authState.signOutFirebase();
  }
}
