import { Injectable } from '@angular/core';
import { UserDatabase } from '@sap/logic/firestore-database/user-database';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { UserInfo } from '@sap/logic/shared/models/user-info';
import { ModuleScopes, UserPermissionsPayload } from '@sap/logic/shared/types/permissions';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { BrandType } from '@sap/shared/enums';
import { LocalStorageService } from '@sap/shared/services/local-storage.service';
import { SessionStorageService } from '@sap/shared/services/session-storage.service';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';
import { UserAuthState } from '../enums';
import { mapToModulePermissions } from './map-to-module-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthState {
  private _authState$: BehaviorSubject<UserAuthState> = new BehaviorSubject<UserAuthState>(UserAuthState.notChecked);
  private _userInfo$: BehaviorSubject<UserInfo | undefined> = new BehaviorSubject<UserInfo | undefined>(undefined);
  private _authToken$: BehaviorSubject<AuthToken | undefined> = new BehaviorSubject<AuthToken | undefined>(undefined);
  private _userPermissions$: ReplaySubject<UserPermissionsPayload> = new ReplaySubject<UserPermissionsPayload>(1);

  constructor(
    private _firestoreDatabase: FirestoreDatabase,
    private _userDatabase: UserDatabase,
    private _sessionStorageService: SessionStorageService,
    private _localStorageService: LocalStorageService
  ) {}

  public get getAuthStateValue(): UserAuthState {
    return this._authState$.getValue();
  }

  public get userInfoEmail(): string | undefined {
    return this._userInfo$.getValue()?.email;
  }

  public getAuthState(): Observable<UserAuthState> {
    return this._authState$.pipe(distinctUntilChanged());
  }

  public getAuthStateChecked(): Observable<UserAuthState> {
    return this.getAuthState().pipe(filter((state: UserAuthState) => state !== UserAuthState.notChecked));
  }

  public getAuthToken(): Observable<AuthToken | undefined> {
    return this._authToken$;
  }

  public getUserInfo(): BehaviorSubject<UserInfo | undefined> {
    return this._userInfo$;
  }

  public getUserEmail(): Observable<string> {
    return this._userInfo$.pipe(
      filter(Boolean),
      map((user: UserInfo) => user.email)
    );
  }

  public getUserInfoId(): Observable<number> {
    return this._userInfo$.pipe(
      filter(Boolean),
      map((user: UserInfo) => user.id)
    );
  }

  public getUserPermissions(): Observable<UserPermissionsPayload> {
    return this._userPermissions$;
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getAuthState().pipe(
      map((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      distinctUntilChanged()
    );
  }

  public isCheckedAndLoggedIn(): Observable<boolean> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state !== UserAuthState.notChecked),
      map((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      distinctUntilChanged()
    );
  }

  public isLoggedInSignal(): Observable<void> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      mapTo(undefined)
    );
  }

  public isNotLoggedInSignal(): Observable<void> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state === UserAuthState.isNotLoggedIn),
      distinctUntilChanged(),
      mapTo(undefined)
    );
  }

  public changeAuthState(state: UserAuthState): void {
    this._authState$.next(state);
  }

  public setAuthToken(authToken: AuthToken): void {
    this._userInfo$.next(UserInfo.fromJson(jwtDecode(authToken.accessToken)));
    this._authToken$.next(authToken);
  }

  public signOutFirebase(): void {
    this._firestoreDatabase.signOutFirebase();
    this._firestoreDatabase.userSignOutSignal().subscribe(() => this.changeAuthState(UserAuthState.isNotLoggedIn));
  }

  public logout(): void {
    this._userDatabase.cleanUserPermissions(this.userInfoEmail!);
    this._sessionStorageService.clear();
    this._localStorageService.clear();
    this.signOutFirebase();
  }

  public setModulePermissions({ brandsScopes, allowedModules }: AuthToken): void {
    if (brandsScopes && allowedModules) {
      const brandScopes: BrandType[] = Object.keys(brandsScopes) as BrandType[];
      const modulePermissions: Record<string, Record<string, boolean>> = mapToModulePermissions(
        brandsScopes,
        allowedModules
      );
      this._userPermissions$.next([modulePermissions as ModuleScopes<boolean>, brandScopes]);
    }
  }
}
