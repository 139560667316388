import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserDatabaseSettings } from '@sap/logic/shared/models/user-database-settings';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { NavigationItem } from '@sap/ui/navs/left-navigation-drawer/left-navigation-drawer.component';
import { SetAndDetect, setAndDetect } from '@sap/ui/shared/helpers/set-and-detect';
import { Theme } from '@sap/ui/shared/services/set-theme.service';
import { PanelContainerBloc } from 'libs/logic/panel-container/src/lib/bloc/panel-container/panel-container.bloc';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'sap-panel-container',
  templateUrl: './panel-container.component.html',
  styleUrls: ['./panel-container.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [PanelContainerBloc]
})
export class PanelContainerComponent extends Unsubscribable implements OnInit {
  private _set: SetAndDetect<this>;
  public currentUrl: string | undefined;
  public userSettings: UserDatabaseSettings | undefined;
  public userEmail: string | undefined;
  public selectedTheme: Theme = Theme.dark;
  public isRightSidebarVisible: boolean = true;
  public navigationItems: NavigationItem[] = [];

  public doLogout$: ReplaySubject<void> = this._bloc.feedValues.doLogout$;
  public doToggleTheme$: ReplaySubject<Theme> = this._bloc.feedValues.doToggleTheme$;
  public doToggleMenu$: ReplaySubject<boolean> = this._bloc.feedValues.doToggleMenu$;
  public doExpandSubMenu$: ReplaySubject<Record<number, boolean>> = this._bloc.feedValues.doExpandSubMenu$;
  public doToggleFilters$: ReplaySubject<boolean> = this._bloc.feedValues.doToggleFilters$;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _bloc: PanelContainerBloc
  ) {
    super();
    this._set = setAndDetect(this, this._cdRef);
  }

  public ngOnInit(): void {
    this._sub = this._bloc.feedValues.userSettings.subscribe(this._set('userSettings'));
    this._sub = this._bloc.feedValues.userEmail.subscribe(this._set('userEmail'));
    this._sub = this._bloc.feedValues.currentUrl.subscribe(this._set('currentUrl'));
    this._sub = this._bloc.feedValues.currentTheme.subscribe(this._set('selectedTheme'));
    this._sub = this._bloc.feedValues.navigationItems.subscribe(this._set('navigationItems'));
    this._sub = this.doLogout$.subscribe(() => this._unsubscribeAll());
  }
}
