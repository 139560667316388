import { moduleScopes } from '@sap/logic/shared/constants/module-scopes';
import { ModuleScopes } from '@sap/logic/shared/types/permissions';
import * as R from 'rambdax';

export function mapToModulePermissions(
  brandsScopes: Record<string, string[]>,
  allowedModules: string[]
): Record<string, Record<string, boolean>> {
  const allScopesFromResponse: string[] = R.uniq(Object.values(brandsScopes).flat());
  const modulePermissions: Record<string, Record<string, boolean>> = {};
  for (const moduleName of Object.keys(moduleScopes)) {
    const moduleNameTyped: keyof ModuleScopes<boolean> = moduleName as keyof ModuleScopes<boolean>;
    for (const subModuleName of Object.keys(moduleScopes[moduleName])) {
      const allowedModulesIncludedModuleName: boolean = allowedModules.some((allowedModule: string) =>
        moduleName.includes(allowedModule)
      );
      const allowedModulesIncludedSubModuleName: boolean = allowedModules.some((allowedModule: string) =>
        subModuleName.includes(allowedModule)
      );
      if (
        allowedModulesIncludedModuleName ||
        allowedModulesIncludedSubModuleName ||
        moduleName === 'users' ||
        subModuleName === 'main'
      ) {
        let hasAccess: boolean;
        if (subModuleName === 'main') {
          hasAccess =
            Object.keys(moduleScopes[moduleName]).length <= 1
              ? allowedModulesIncludedModuleName
              : Object.values(modulePermissions[moduleNameTyped] ?? {}).includes(true);
        } else {
          hasAccess =
            (allowedModulesIncludedModuleName || allowedModulesIncludedSubModuleName) &&
            moduleScopes[moduleName][subModuleName].every((scope: string) => allScopesFromResponse.includes(scope));
        }

        modulePermissions[moduleNameTyped] = {
          ...modulePermissions[moduleNameTyped],
          [subModuleName]: hasAccess
        };
      }
    }
  }
  return modulePermissions;
}
