import { Injectable } from '@angular/core';
import { SportsGroupRepository } from '@sap/logic/api-access/repository/sports-group-repository';
import { SortMode } from '@sap/logic/shared/enums';
import { SportsGroup } from '@sap/logic/shared/models/sports-group';
import { sortDataByNumberProp } from '@sap/logic/shared/utils/sort-pure-utils';
import { mapToUpdateRestrictionChanges } from '@sap/logic/sports-groups-restrictions/bloc/sports-groups-restrictions/pure-utils';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { BrandTypeWithoutDots } from '@sap/shared/enums';
import { asPromise } from '@sap/shared/helpers/rx-helpers';
import { isSameDay } from 'date-fns';
import { update, UseStore } from 'idb-keyval';
import * as R from 'rambdax';
import { first, forkJoin, from, Observable, ReplaySubject, retry, Subject } from 'rxjs';
import { IndexdbStorage } from './indexdb-storage';
import { indexesBySportGroupId, reduceSportGroupsToDisciplinesRegionsAndLeagues } from './pure-utils';

enum SportsGroupsTables {
  sportsGroups = 'sportsGroups',
  indexesBySportGroupId = 'indexesBySportGroupId',
  version = 'version',
  unassignedBrands = 'unassignedBrands',
  timeLoaded = 'timeLoaded'
}

@Injectable({ providedIn: 'root' })
export class SportsGroupsLoader {
  public isLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public didRefreshDB$: Subject<void> = new Subject<void>();
  public lastTimeLoaded$: ReplaySubject<Date> = new ReplaySubject<Date>(1);

  private _storeRef!: UseStore;
  private _version!: number;

  constructor(
    private _sportsGroupsRepository: SportsGroupRepository,
    private _settings: SharedSettings,
    private _indexdbStorage: IndexdbStorage
  ) {}

  public async init(): Promise<void> {
    this._version = Number(this._settings.environment.releaseVersion.replace(/\./g, ''));
    this._getStore();
    this._fetchAllSportsGroups();
  }

  private async _fetchAllSportsGroups(): Promise<void> {
    const store: UseStore = await this._getStore();
    const fromDbByIds: SportsGroup[] = (await this._indexdbStorage.get(SportsGroupsTables.sportsGroups, store)) ?? [];
    const tableVersion: number | undefined = await this._indexdbStorage.get(SportsGroupsTables.version, store);
    const isLoaded: boolean = this._version === tableVersion && fromDbByIds.length > 0;
    const timeLoaded: number | undefined = await this._indexdbStorage.get(SportsGroupsTables.timeLoaded, store);
    const shouldReloadDb: boolean = timeLoaded ? !isSameDay(Date.now(), timeLoaded) : true;
    if (isLoaded && !shouldReloadDb) {
      this.isLoaded$.next(true);
      this.lastTimeLoaded$.next(new Date(timeLoaded!));
      return;
    }
    this.fetchAndStoreSportsGroups();
  }

  public async fetchAndStoreSportsGroups(isReload: boolean = false): Promise<void> {
    const store: UseStore = await this._getStore();
    this.isLoaded$.next(false);
    const offset: number = 3000;
    const count: number = await this._sportsGroupsRepository.getSportsGroupsCount();
    const requestsCount: number = Math.ceil(count / offset);
    const requestToMake: Observable<SportsGroup[]>[] = [];
    for (let i: number = 0; i < requestsCount; i++) {
      const request: Observable<SportsGroup[]> = from(
        this._sportsGroupsRepository.getSportsGroups(i * offset, offset)
      ).pipe(retry());
      requestToMake.push(request);
    }
    const requestResult: SportsGroup[] = R.flatten(await asPromise(forkJoin(requestToMake)));
    const sortedRequestResult: SportsGroup[] = sortDataByNumberProp(requestResult, SortMode.asc, 'id');
    const indexesBySportGroupIds: Record<number, number> = indexesBySportGroupId(sortedRequestResult);
    const timeLoaded: number = Date.now();

    await this._indexdbStorage.clear(store);
    await this._indexdbStorage.set(SportsGroupsTables.sportsGroups, sortedRequestResult, store);
    await this._indexdbStorage.set(SportsGroupsTables.indexesBySportGroupId, indexesBySportGroupIds, store);
    await this._indexdbStorage.set(SportsGroupsTables.version, this._version, store);
    await this._indexdbStorage.set(SportsGroupsTables.unassignedBrands, false, store);
    await this._indexdbStorage.set(SportsGroupsTables.timeLoaded, timeLoaded, store);
    this.lastTimeLoaded$.next(new Date(timeLoaded));
    this.isLoaded$.next(true);
    if (isReload) {
      this.didRefreshDB$.next(undefined);
      console.info('SGs reloaded to indexDB');
    } else {
      console.info('SGs loaded to indexDB');
    }
  }

  public async getByIds(ids: number[]): Promise<SportsGroup[]> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const fromDb: SportsGroup[] = (await this._indexdbStorage.get(SportsGroupsTables.sportsGroups, store)) ?? [];
    return R.filter((sportGroup: SportsGroup) => ids.includes(sportGroup.id), fromDb!);
  }

  public async getDisciplines(): Promise<SportsGroup[]> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const fromDb: SportsGroup[] = (await this._indexdbStorage.get(SportsGroupsTables.sportsGroups, store)) ?? [];
    const disciplines: SportsGroup[] = fromDb.filter((sportGroup: SportsGroup) => sportGroup.parentId === null);
    return SportsGroup.fromDbArray(disciplines);
  }

  public async getLeagues(): Promise<SportsGroup[]> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const fromDb: SportsGroup[] = (await this._indexdbStorage.get(SportsGroupsTables.sportsGroups, store)) ?? [];
    const leagues: SportsGroup[] = reduceSportGroupsToDisciplinesRegionsAndLeagues(fromDb).leagues;
    return SportsGroup.fromDbArray(leagues);
  }

  public async getAll(): Promise<SportsGroup[]> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const result: SportsGroup[] = (await this._indexdbStorage.get(SportsGroupsTables.sportsGroups, store)) ?? [];
    return SportsGroup.fromDbArray(result);
  }

  public async getUnassignedBrandFilter(): Promise<boolean> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const result: boolean = (await this._indexdbStorage.get(SportsGroupsTables.unassignedBrands, store)) ?? false;
    return result;
  }

  public async getIndexesBySportGroupId(): Promise<Record<number, number>> {
    await asPromise(this.isLoaded$.pipe(first((isLoaded: boolean) => isLoaded)));
    const store: UseStore = await this._getStore();
    const result: Record<number, number> =
      (await this._indexdbStorage.get(SportsGroupsTables.indexesBySportGroupId, store)) ?? {};
    return result;
  }

  public async updateUnassignedBrands(unassignedBrands: boolean): Promise<void> {
    const store: UseStore = await this._getStore();
    await update(
      SportsGroupsTables.unassignedBrands,
      (currentUnassignedBrands: boolean | undefined): boolean => {
        currentUnassignedBrands = unassignedBrands;
        return currentUnassignedBrands;
      },
      store
    );
  }

  public async updateRestriction(
    editedSportGroups: SportsGroup[],
    brand: BrandTypeWithoutDots,
    updatedRestriction: SportsGroup
  ): Promise<void> {
    const store: UseStore = await this._getStore();
    await update(
      SportsGroupsTables.sportsGroups,
      (currentSportGroups: SportsGroup[] | undefined): SportsGroup[] => {
        if (currentSportGroups) {
          for (const editedSportGroup of editedSportGroups) {
            const index: number = currentSportGroups.findIndex(R.propEq('id', editedSportGroup.id));
            currentSportGroups = R.adjust(
              index,
              () => mapToUpdateRestrictionChanges(editedSportGroup, brand, updatedRestriction),
              currentSportGroups as SportsGroup[]
            );
          }
        }
        return (currentSportGroups ?? []) as SportsGroup[];
      },
      store
    );
  }

  public async updateSportGroupsIndexDBWithEdited(editedSportGroups: SportsGroup[]): Promise<void> {
    const store: UseStore = await this._getStore();
    const indexesBySportGroupIds: Record<number, number> = (await this._indexdbStorage.get(
      SportsGroupsTables.indexesBySportGroupId,
      store
    ))!;
    await update(
      SportsGroupsTables.sportsGroups,
      (currentSportGroups: SportsGroup[] | undefined): SportsGroup[] => {
        if (currentSportGroups) {
          for (const editedSportGroup of editedSportGroups) {
            currentSportGroups = R.adjust(
              indexesBySportGroupIds[editedSportGroup.id],
              () => editedSportGroup,
              currentSportGroups as SportsGroup[]
            );
          }
        }
        return (currentSportGroups ?? []) as SportsGroup[];
      },
      store
    );
  }

  public async addNewSportGroupToIndexDB(newSportGroup: SportsGroup): Promise<void> {
    const store: UseStore = await this._getStore();
    await update(
      SportsGroupsTables.sportsGroups,
      (currentSportGroups: SportsGroup[] | undefined): SportsGroup[] => {
        if (currentSportGroups) {
          currentSportGroups = R.concat(currentSportGroups, [newSportGroup] as SportsGroup[]);
        }
        return (currentSportGroups ?? []) as SportsGroup[];
      },
      store
    );
  }

  public async removeSportGroupFromIndexDB(removedId: number): Promise<void> {
    const store: UseStore = await this._getStore();
    await update(
      SportsGroupsTables.sportsGroups,
      (currentSportGroups: SportsGroup[] | undefined): SportsGroup[] => {
        if (currentSportGroups) {
          currentSportGroups = R.filter(
            (sportGroup: SportsGroup) => sportGroup.id !== removedId,
            currentSportGroups as SportsGroup[]
          );
        }
        return (currentSportGroups ?? []) as SportsGroup[];
      },
      store
    );
  }

  private async _getStore(): Promise<UseStore> {
    return (this._storeRef ??= await this._indexdbStorage.createStore(SportsGroupsTables.sportsGroups));
  }
}
