import { NavigationItem } from '../left-navigation-drawer.component';

export const contentMain: NavigationItem = {
  title: 'Content',
  url: undefined,
  icon: 'soccer',
  scope: 'content.main'
};

export const SportsGroupsSorting: NavigationItem = {
  title: 'Sports Groups Sorting',
  url: '/sports-groups-sorting',
  icon: 'sort-alpha-down',
  scope: 'content.sportGroupsSorting'
};

export const CompetitionsMapping: NavigationItem = {
  title: 'Competitions Mapping',
  url: '/competitions-mapping',
  icon: 'share-alt',
  scope: 'content.competitionMapping'
};

export const PrimaryColumns: NavigationItem = {
  title: 'Primary Columns',
  url: '/primary-columns',
  icon: 'columns',
  scope: 'content.primaryColumns'
};

export const MarketGroups: NavigationItem = {
  title: 'Market Groups',
  url: '/market-groups',
  icon: 'puzzle',
  scope: 'content.marketGroups'
};

export const Translations: NavigationItem = {
  title: 'Translations',
  url: '/translations',
  icon: 'translate',
  scope: 'content.translations'
};

export const RecommendedStandard: NavigationItem = {
  title: 'Recommended Standard',
  url: '/recommended',
  icon: 'badge',
  scope: 'content.recommendedStandard'
};

export const RecommendedEsport: NavigationItem = {
  title: 'Recommended Esport',
  url: '/esport-recommended',
  icon: 'gamepad',
  scope: 'content.recommendedEsports'
};

export const allContentChildren: NavigationItem[] = [
  SportsGroupsSorting,
  CompetitionsMapping,
  PrimaryColumns,
  MarketGroups,
  Translations,
  RecommendedStandard,
  RecommendedEsport
];
