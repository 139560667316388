import { Inject, Injectable } from '@angular/core';
import { SESSIONSTORAGE } from '@sap/shared-platform/providers/session-storage-provider';
import { NavigationItem } from '@sap/ui/navs/left-navigation-drawer/left-navigation-drawer.component';
import { Observable, Subject } from 'rxjs';
import { SessionStorageKeys } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private _didUpdate$: Subject<void> = new Subject<void>();

  constructor(@Inject(SESSIONSTORAGE) private _sessionStorage: Storage) {}

  public clear(): void {
    this._sessionStorage.clear();
  }

  public hasKey(key: SessionStorageKeys): boolean {
    return this._sessionStorage.getItem(key) === null ? false : true;
  }

  public getString(key: SessionStorageKeys): string | null {
    return this._sessionStorage.getItem(key);
  }

  public setString(key: SessionStorageKeys, value: string): void {
    this._sessionStorage.setItem(key, value);
    this._didUpdate$.next(undefined);
  }

  public getNumber(key: SessionStorageKeys): number {
    return Number(this._sessionStorage.getItem(key));
  }

  public setNumber(key: SessionStorageKeys, value: number): void {
    this._sessionStorage.setItem(key, value?.toString());
    this._didUpdate$.next(undefined);
  }

  public getBoolean(key: SessionStorageKeys): boolean {
    return this._sessionStorage.getItem(key) === 'true';
  }

  public setBoolean(key: SessionStorageKeys, value: boolean): void {
    this._sessionStorage.setItem(key, value.toString());
    this._didUpdate$.next(undefined);
  }

  public getJson<T>(key: SessionStorageKeys): T | undefined {
    const value: string | null = this._sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  public setJson<T>(key: SessionStorageKeys, value: T): void {
    this._sessionStorage.setItem(key, JSON.stringify(value));
    this._didUpdate$.next(undefined);
  }

  public remove(key: SessionStorageKeys): void {
    this._sessionStorage.removeItem(key);
    this._didUpdate$.next(undefined);
  }

  public didUpdated(): Observable<void> {
    return this._didUpdate$.asObservable();
  }

  public clearSessionsKeysAfterCloseNavItem(navItem: NavigationItem): void {
    if (navItem.sessionStorageKeyToRemoveOnClose) {
      this.remove(navItem.sessionStorageKeyToRemoveOnClose);
    }

    if (navItem.url && this.hasKey(SessionStorageKeys.scrollTopPositions)) {
      const storedScrollPositions: Record<string, number> = this.getJson(SessionStorageKeys.scrollTopPositions)!;
      if (storedScrollPositions.hasOwnProperty(navItem.url)) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { [navItem.url]: _toRemove, ...rest }: Record<string, number> = storedScrollPositions;
        this.setJson(SessionStorageKeys.scrollTopPositions, rest);
      }
    }
  }
}
