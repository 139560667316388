<ng-container fxThemeCss></ng-container>
<ng-container *templateVarTyped="let vars = vars; let update = updateVar; let updateObject = updateVarObject">
  <nav class="sidebar" [class.sidebar_expanded]="isExpanded">
    <ul class="sidebar-nav">
      <ng-container *ngIf="brandsFromScopes && userPermissions">
        <ng-container *ngFor="let navItem of navigation; let index = index">
          <ng-container
            [ngTemplateOutlet]="liMenuItem"
            [ngTemplateOutletContext]="{ navItem: navItem, index: index, isChildren: false }"
          >
          </ng-container>
        </ng-container>
      </ng-container>
      <li class="nav-item main">
        <a class="nav-link" (click)="doLogout.next(undefined)">
          <sapds-icon class="nav-icon" iconName="account-logout" />
          <span>Logout</span>
        </a>
      </li>
    </ul>
    <sapds-button class="sidebar-toggler" (click)="doToggleMenu.next(!isExpanded)"></sapds-button>
  </nav>

  <!-- Templates-->
  <ng-template #liMenuItem let-index="index" let-navItem="navItem" let-isChildren="isChildren">
    <ng-container *ngIf="navItem.scope | permissionCheck: userPermissions!">
      <ng-container *ngIf="navItem | brandScopeCheck: isChildren : brandsFromScopes : userPermissions!">
        <li
          class="nav-item"
          [class.main]="!isChildren"
          [class.active]="navItem.children ? (navItem.children | isActiveNavItem: currentUrl) : false"
        >
          <a
            *ngIf="expandedSubMenus | toUpdatedExpandedMenu: index as updatedValue"
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="navItem | navRouterLink: nextBetTracerTab"
            (click)="!isChildren && isExpanded && doExpandSubMenu.next(updatedValue)"
          >
            <sapds-icon [iconName]="navItem.icon" class="nav-icon" />
            <span [class.nav-link__text]="isChildren">{{ navItem.title }}</span>
            <sapds-icon
              *ngIf="navItem.children"
              class="chevron-icon"
              [iconName]="expandedSubMenus[index] ? 'chevron-bottom' : 'chevron-left'"
            />
          </a>
          <ul
            *ngIf="navItem.children"
            class="nav-dropdown-items"
            [class.open]="navItem.children && expandedSubMenus[index]"
          >
            <ng-container *ngFor="let navItem of navItem.children; let index = index">
              <ng-container
                [ngTemplateOutlet]="liMenuItem"
                [ngTemplateOutletContext]="{ navItem: navItem, index: index, isChildren: true }"
              >
              </ng-container>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
