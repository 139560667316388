import { Injectable } from '@angular/core';
import { SharedBrand } from '@sap/logic/shared/brand/shared-brand';
import { BrandType, LocalStorageKeys } from '@sap/shared/enums';
import { transferRxNext } from '@sap/shared/helpers/rx-helpers';
import { LocalStorageService } from '@sap/shared/services/local-storage.service';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BrandSettings {
  public selectedBrand$: ReplaySubject<BrandType> = new ReplaySubject<BrandType>(1);

  constructor(
    private _localStorageService: LocalStorageService,
    private _sharedBrand: SharedBrand
  ) {}

  public init(): void {
    const brand: BrandType | undefined = this._localStorageService.getString(
      LocalStorageKeys.selectedBrand
    ) as BrandType;

    this._sharedBrand.getInitialBrand(undefined, brand).subscribe(transferRxNext(this.selectedBrand$));

    this.selectedBrand$.subscribe((brand: BrandType) =>
      this._localStorageService.setString(LocalStorageKeys.selectedBrand, brand)
    );
  }
}
