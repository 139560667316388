import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

export const LOCALSTORAGE: InjectionToken<unknown> = new InjectionToken('LocalStorageToken');

class FakeLocalStorage implements Storage {
  [name: string]: any;
  public length!: number;
  clear(): void {}
  public getItem(_key: string): string | null {
    return null;
  }
  public key(_index: number): string | null {
    return null;
  }
  removeItem(_key: string): void {}
  setItem(_key: string, _value: string): void {}
}

export abstract class LocalStorageRef {
  get nativeLocalStorage(): Storage {
    throw new Error('Not implemented.');
  }
}

@Injectable()
export class BrowserLocalStorageRef extends LocalStorageRef {
  constructor() {
    super();
  }

  get nativeLocalStorage(): Storage {
    return localStorage;
  }
}

export function localStorageFactory(
  browserLocalStorageRef: BrowserLocalStorageRef,
  platformId: Record<string, any>
): Storage {
  if (isPlatformBrowser(platformId)) {
    return browserLocalStorageRef.nativeLocalStorage;
  }
  return new FakeLocalStorage();
}

const browserLocalStorageProvider: ClassProvider = {
  provide: LocalStorageRef,
  useClass: BrowserLocalStorageRef
};

const localStorageProvider: FactoryProvider = {
  provide: LOCALSTORAGE,
  useFactory: localStorageFactory,
  deps: [LocalStorageRef, PLATFORM_ID]
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LOCALSTORAGE_PROVIDERS: (ClassProvider | FactoryProvider)[] = [
  browserLocalStorageProvider,
  localStorageProvider
];
