<ng-container fxThemeCss></ng-container>

<footer class="footer" [class.footer_left-expanded]="isMenuExpanded" [class.footer_right-expanded]="isFiltersExpanded">
  <div class="user-info">
    Email:
    <span class="blue">{{ userEmail }} </span>
  </div>
  <div class="settings">
    <div class="utc-clock">{{ undefined | utcTimer }}</div>

    <!-- <div *ngIf="true" class="settings__favorite"> -->
    <!-- TODO -->
    <!-- <sapds-icon
        fxThemeCssNotShadowDomChild
        [iconName]="displayOnlyFavoriteSportsActivated ? 'favorite-active' : 'favorite'"
        [title]="displayOnlyFavoriteSportsActivated ? 'Display all Sports.' : 'Display only Favorite Sports.'"
        (click)="doToggleFavoriteSportsVisible.next(!displayOnlyFavoriteSportsActivated)"
      /> -->
    <!-- </div> -->
    <div class="settings__theme">
      <sapds-icon
        *ngIf="isDarkTheme"
        class="u-clickable"
        iconName="sun"
        title="Switch to Light Theme"
        (click)="doToggleTheme.next(Theme.light)"
      ></sapds-icon>
      <sapds-icon
        *ngIf="!isDarkTheme"
        class="u-clickable"
        iconName="moon"
        title="Switch to Dark Theme"
        (click)="doToggleTheme.next(Theme.dark)"
      ></sapds-icon>
    </div>
  </div>
</footer>
