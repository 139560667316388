import { Pipe, PipeTransform } from '@angular/core';
import { NavigationItem } from '@sap/ui/navs/left-navigation-drawer/left-navigation-drawer.component';
import {
  betTracerMain,
  maxOpenedTabsForBetTracer
} from '@sap/ui/navs/left-navigation-drawer/navigation-items/bet-tracer';
import { betTracerIndexInstances } from '@sap/ui/pages/bet-tracer-page/bet-tracer-page-routing.module';

@Pipe({ name: 'countBetTracerTabs', standalone: true })
export class CountBetTracerTabsPipe implements PipeTransform {
  public transform(navItems: NavigationItem[]): number {
    const betTracerTabs: number[] = navItems
      .filter((item: NavigationItem) => item.url?.startsWith(betTracerMain.url!) && item.url?.split('/').length <= 3)
      .map((item: NavigationItem) => Number(item.url?.split('/')[2] ?? 0));
    if (betTracerTabs.length >= maxOpenedTabsForBetTracer) {
      return betTracerTabs.at(-1)!;
    }
    for (const instance of [0, ...betTracerIndexInstances]) {
      if (betTracerTabs.indexOf(instance) === -1) {
        return instance;
      }
    }
    return 0;
  }
}
