import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

export const NAVIGATOR: InjectionToken<unknown> = new InjectionToken('NavigatorToken');

export abstract class NavigatorRef {
  public get nativeNavigator(): Navigator | Record<string, any> {
    throw new Error('Not implemented.');
  }
}

@Injectable()
export class BrowserNavigatorRef extends NavigatorRef {
  constructor() {
    super();
  }

  public override get nativeNavigator(): Navigator | Record<string, any> {
    return navigator;
  }
}

export function navigatorFactory(
  browserNavigatorRef: BrowserNavigatorRef,
  platformId: Record<string, any>
): Window | Record<string, any> {
  if (isPlatformBrowser(platformId)) {
    return browserNavigatorRef.nativeNavigator;
  }
  return new Object();
}

const browserNavigatorProvider: ClassProvider = {
  provide: NavigatorRef,
  useClass: BrowserNavigatorRef
};

const navigatorProvider: FactoryProvider = {
  provide: NAVIGATOR,
  useFactory: navigatorFactory,
  deps: [NavigatorRef, PLATFORM_ID]
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NAVIGATOR_PROVIDERS: (ClassProvider | FactoryProvider)[] = [browserNavigatorProvider, navigatorProvider];
