import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthChecker } from '@sap/logic/auth/services/auth-checker';
import { LOCALSTORAGE_PROVIDERS } from '@sap/shared-platform/providers/local-storage-provider';
import { NAVIGATOR_PROVIDERS } from '@sap/shared-platform/providers/navigator-provider';
import { provideBrowserSessionStorage } from '@sap/shared-platform/providers/session-storage-provider';
import { WINDOW_PROVIDERS } from '@sap/shared-platform/providers/window-provider';
import { SharedSettings } from '@sap/shared-settings/shared-settings';
import { RouterPlacesService } from '@sap/ui/shared/services/router-places.service';
import { environment } from 'configs/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), AppRoutingModule, HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (sharedSettings: SharedSettings, authChecker: AuthChecker, routerPlacesService: RouterPlacesService) =>
        async (): Promise<void> => {
          sharedSettings.environment = environment;
          sharedSettings.setReady();
          authChecker.init();
          routerPlacesService.init();
        },
      deps: [SharedSettings, AuthChecker, RouterPlacesService],
      multi: true
    },
    LOCALSTORAGE_PROVIDERS,
    WINDOW_PROVIDERS,
    NAVIGATOR_PROVIDERS,
    provideBrowserSessionStorage()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
