import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, SubscriptionLike } from 'rxjs';

@Injectable()
export class Unsubscribable implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  protected set _sub(sub: SubscriptionLike | undefined) {
    if (Boolean(sub)) {
      this._subscriptions.add(sub);
    }
  }

  protected _unsubscribeAll(): void {
    this._subscriptions.unsubscribe();
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll();
  }
}
