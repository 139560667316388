import { Injectable } from '@angular/core';
import { MessageError } from '@sap/logic/api-access/errors/message-error';
import { AuthRepository } from '@sap/logic/api-access/repository/auth-repository';
import { AuthToken } from '@sap/logic/shared/models/auth-tokens';
import { FirestoreDatabase } from '@sap/shared-platform/providers/firestore/firestore-database';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { zonedTimeToUtc } from '@sap/shared/miscs/utils';
import { differenceInMilliseconds, isBefore } from 'date-fns';
import { combineLatest, timer } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserAuthState } from '../enums';
import { AuthState } from './auth-state';
import { AuthTokenStorage } from './auth-token-storage';

@Injectable({ providedIn: 'root' })
export class AuthChecker extends Unsubscribable {
  constructor(
    private _authTokenStorage: AuthTokenStorage,
    private _authState: AuthState,
    private _authRepository: AuthRepository,
    private _firestoreDatabase: FirestoreDatabase
  ) {
    super();
  }

  public init(): void {
    this._checkIfTokenExpires();
    this._tokenExpiresWatcher();
    this._signInToFirestore();
  }

  private _checkIfTokenExpires(): void {
    const authToken: AuthToken | undefined = this._authTokenStorage.getAuthToken();
    if (authToken) {
      const isNotExpiresToken: boolean = isBefore(zonedTimeToUtc(new Date()), authToken.expiresAt);
      const isNotExpiresRefreshToken: boolean = isBefore(zonedTimeToUtc(new Date()), authToken.refreshTokenExpiresAt);
      console.logDev('isNotExpiresToken', isNotExpiresToken);
      console.logDev('isNotExpiresRefreshToken', isNotExpiresRefreshToken);
      if (isNotExpiresToken || isNotExpiresRefreshToken) {
        this._authState.setAuthToken(authToken);
        this._authState.changeAuthState(UserAuthState.isLoggedIn);
        return;
      }
    }
    this._authState.changeAuthState(UserAuthState.isNotLoggedIn);
  }

  private _tokenExpiresWatcher(): void {
    this._sub = this._authState
      .getAuthToken()
      .pipe(
        filter(Boolean),
        switchMap((authToken: AuthToken) => {
          const millisecondsToRefreshToken: number = differenceInMilliseconds(
            new Date(authToken.expiresAt),
            zonedTimeToUtc(new Date())
          );
          console.logDev('ms to refresh token', millisecondsToRefreshToken);
          if (millisecondsToRefreshToken < 0) {
            return this._authRepository.authRefresh();
          }
          return timer(millisecondsToRefreshToken).pipe(mergeMap(() => this._authRepository.authRefresh()));
        })
      )
      .subscribe((result: AuthToken | MessageError) => {
        if (result instanceof AuthToken) {
          this._authTokenStorage.saveAuthToken(result);
          this._authState.setAuthToken(result);
          this._authState.changeAuthState(UserAuthState.isLoggedIn);
        } else {
          this._authTokenStorage.clearAuthToken();
          this._authState.changeAuthState(UserAuthState.isNotLoggedIn);
        }
      });
  }

  private _signInToFirestore(): void {
    this._sub = combineLatest([
      this._authState.getAuthToken().pipe(
        filter(Boolean),
        map((authToken: AuthToken) => authToken.accessToken)
      ),
      this._authState.getUserEmail()
    ]).subscribe(([accessToken, userEmail]: [string, string]) =>
      this._firestoreDatabase.signInToFirebaseWithCustomToken(accessToken, userEmail)
    );
  }
}
