import { Pipe, PipeTransform } from '@angular/core';
import { ModuleScopes } from '@sap/logic/shared/types/permissions';
import { BrandType } from '@sap/shared/enums';
import { NavigationItem } from '../left-navigation-drawer.component';
import { resolvePath } from './permission-check.pipe';

@Pipe({ name: 'brandScopeCheck', standalone: true })
export class BrandScopeCheckPipe implements PipeTransform {
  public transform(
    navItem: NavigationItem,
    isChildren: boolean,
    brandsScopes: BrandType[],
    permissions: ModuleScopes<boolean>
  ): boolean {
    if (isChildren) {
      return navItem.brandsScope && brandsScopes
        ? brandsScopes.some((brand: BrandType) => navItem.brandsScope?.includes(brand))
        : true;
    }
    if (isChildren && brandsScopes && navItem.brandsScope) {
      return brandsScopes.some((brand: BrandType) => navItem.brandsScope?.includes(brand));
    }
    if (navItem.brandsScope && brandsScopes) {
      return brandsScopes.some((brand: BrandType) => navItem.brandsScope?.includes(brand));
    }
    if (navItem.children) {
      const navChildren: NavigationItem[] = navItem.children;
      const requiredBrandChild: boolean = navChildren.some((item: NavigationItem) => item.brandsScope);
      const hasPermissionToAnyChildren: boolean = navChildren.some((item: NavigationItem) =>
        resolvePath(item.scope, permissions)
      );

      if (requiredBrandChild && hasPermissionToAnyChildren) {
        const requiredBrandToAnyChildren: NavigationItem[] = navChildren.filter(
          (item: NavigationItem) =>
            item.brandsScope &&
            brandsScopes &&
            brandsScopes.some((brand: BrandType) => item.brandsScope?.includes(brand))
        );
        const notRequiredBrandToAnyChildren: NavigationItem[] = navChildren.filter(
          (item: NavigationItem) => !item.brandsScope
        );
        const hasPermission: boolean = [...requiredBrandToAnyChildren, ...notRequiredBrandToAnyChildren].some(
          (item: NavigationItem) => resolvePath(item.scope, permissions)
        );
        return hasPermission;
      }
    }

    return true;
  }
}
