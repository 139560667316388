import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Unsubscribable } from '@sap/shared/classes/unsubscribable';
import { Subject, delay, filter } from 'rxjs';

function _visibleInParentViewport(parent: HTMLUListElement, child: HTMLLIElement): boolean {
  const elementRect: DOMRect = child.getBoundingClientRect();
  const parentRect: DOMRect = parent.getBoundingClientRect();
  return elementRect.right >= parentRect.left && elementRect.left + elementRect.width <= parentRect.right;
}

@Directive({
  selector: '[horizontalScroll]',
  standalone: true
})
export class HorizontalScrollDirective extends Unsubscribable implements AfterViewInit {
  private _currentUrl$: Subject<string | undefined> = new Subject();

  @Input() set currentUrl(currentUrl: string | undefined) {
    this._currentUrl$.next(currentUrl);
  }

  constructor(private _element: ElementRef<HTMLUListElement>) {
    super();
  }

  @HostListener('wheel', ['$event'])
  public onScroll(event: WheelEvent): void {
    this._element.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  public ngAfterViewInit(): void {
    this._sub = this._currentUrl$.pipe(filter(Boolean), delay(100)).subscribe((currentUrl: string) => {
      const openedTab: HTMLLIElement | null = this._element.nativeElement.querySelector(`[data-url="${currentUrl}"]`);
      if (openedTab && !_visibleInParentViewport(this._element.nativeElement, openedTab)) {
        this._element.nativeElement.scrollLeft = openedTab.offsetLeft - 50;
      }
    });
  }
}
