import { NavigationItem } from './left-navigation-drawer.component';
import { betTracerMain } from './navigation-items/bet-tracer';
import { allContentChildren, contentMain } from './navigation-items/content';
import { allCrmChildren, crmMain } from './navigation-items/crm';
import { customerSearchMain } from './navigation-items/customer-search';
import { allExternalProductsChildren, externalProductsMain } from './navigation-items/external-products';
import { matchesListMain } from './navigation-items/matches-list';
import { allOfferManagementChildren, offerManagementMain } from './navigation-items/offer-management';
import { allRiskManagementChildren, riskManagementMain } from './navigation-items/risk-management';
import { allSettingsChildren, settingsMain } from './navigation-items/settings';
import { allUsersChildren, usersMain } from './navigation-items/users';

export const navigationList: NavigationItem[] = [
  {
    ...crmMain,
    children: allCrmChildren
  },
  {
    ...contentMain,
    children: allContentChildren
  },
  {
    ...offerManagementMain,
    children: allOfferManagementChildren
  },
  {
    ...riskManagementMain,
    children: allRiskManagementChildren
  },
  customerSearchMain,
  betTracerMain,
  matchesListMain,
  {
    ...externalProductsMain,
    children: allExternalProductsChildren
  },
  {
    ...usersMain,
    children: allUsersChildren
  },
  {
    ...settingsMain,
    children: allSettingsChildren
  }
];
