import { isPlatformBrowser } from '@angular/common';
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

export const WINDOW: InjectionToken<Window | Record<string, any>> = new InjectionToken('WindowToken');

export abstract class WindowRef {
  get nativeWindow(): Window | Record<string, any> {
    throw new Error('Not implemented.');
  }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  get nativeWindow(): Window | Record<string, any> {
    return window;
  }
}

export function windowFactory(
  browserWindowRef: BrowserWindowRef,
  platformId: Record<string, any>
): Window | Record<string, any> {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}

const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef
};

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID]
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WINDOW_PROVIDERS: (ClassProvider | FactoryProvider)[] = [browserWindowProvider, windowProvider];
