import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthTokenStorage } from '@sap/logic/auth/services/auth-token-storage';

export type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class ApiConfig {
  constructor(private _authTokenStorage: AuthTokenStorage) {}

  public get authHeaders(): Record<string, string> {
    return {
      ...(this._accessToken
        ? {
            authorization: `Bearer ${this._accessToken}`
          }
        : {})
    };
  }

  public get authRefreshHeaders(): Record<string, string> {
    return {
      ...(this._refreshToken
        ? {
            Authorization: `Bearer ${this._refreshToken}`
          }
        : {})
    };
  }

  public get authOptions(): HttpOptions {
    return {
      headers: this.authHeaders
    };
  }

  private get _accessToken(): string | undefined {
    return this._authTokenStorage.getAuthToken()?.accessToken;
  }

  private get _refreshToken(): string | undefined {
    return this._authTokenStorage.getAuthToken()?.refreshToken;
  }
}
