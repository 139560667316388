import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Environment } from './types';

@Injectable({
  providedIn: 'root'
})
export class SharedSettings {
  private _environment!: Environment;

  private _ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public set environment(data: Environment) {
    if (this._environment) {
      throw new Error('you can setup environment just once');
    }
    this._environment = data;
  }

  public get environment(): Environment {
    return this._environment;
  }

  public setReady(): void {
    this._ready$.next(true);
  }

  public isReadyCold(): Observable<boolean> {
    return this._ready$.pipe(
      filter((state: boolean) => state === true),
      take(1)
    );
  }
}
