/* eslint-disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'configs/environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Console {
    logDev: (...args: any[]) => void;
  }
}

console.logDev = (...args: any[]): void => {
  if (!environment.production) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { ngZone: 'noop' })
    .catch((err: any) => console.error(err));
});
