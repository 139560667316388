import { NavigationItem } from '../left-navigation-drawer.component';
import { defaultBrand } from './brand';

export const matchesListMain: NavigationItem = {
  title: 'Matches List',
  url: '/matches-list',
  icon: 'spreadsheet',
  queryParams: { isActive: true, brand: defaultBrand },
  scope: 'matchesList.main'
};
