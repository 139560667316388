import { BrandType } from '@sap/shared/enums';
import { Theme } from '@sap/ui/shared/services/set-theme.service';
import { ModuleScopes } from '../types/permissions';

export type UserTheme = Record<string, Theme>;

export class UserDatabaseSettings {
  constructor(
    public readonly uiTheme: UserTheme,
    public readonly permissions: ModuleScopes<boolean>,
    public readonly brandsFromScopes: BrandType[],
    public readonly favoritesOnly: boolean,
    public readonly favoriteSportsIds: number[],
    public readonly isMenuExpanded: boolean,
    public readonly isFiltersExpanded: boolean,
    public readonly expandedSubMenus: Record<number, boolean>
  ) {}

  public static fromJson(json: Record<string, any>): UserDatabaseSettings {
    return new UserDatabaseSettings(
      json['uiTheme'] ?? {},
      json['permissions'],
      json['brandsFromScopes'],
      json['favoritesOnly'] ?? false,
      json['favoriteSportsIds'] ?? [],
      json['isMenuExpanded'] ?? false,
      json['isFiltersExpanded'] ?? true,
      json['expandedSubMenus'] ?? {}
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): UserDatabaseSettings[] {
    return jsons.map(UserDatabaseSettings.fromJson);
  }
}
