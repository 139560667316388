import { BrandInfo } from '@sap/logic/shared/models/brand-info';
import { isStringAndNoEmpty } from '@sap/logic/shared/utils/pure-utils';
import { BrandType } from '../enums';

export function toDouble(value: string | number | boolean): number {
  return Number(Number(value).toFixed(2));
}

export const decimalOddsToAmericanOdd = (value: number): number => {
  if (value === 1) {
    return 1;
  } else {
    let odds: number = 0;
    if (value >= 2) {
      odds = (value - 1) * 100;
    } else {
      odds = -100 / (value - 1);
    }
    return Math.round(odds);
  }
};

export function decimalToFraction(decimal: number, precision: number = 10): string | undefined {
  decimal = parseFloat(decimal.toPrecision(precision));
  return calcFraction(decimal - 1, precision);
}

// for decimalToFraction calc function
function calcFraction(decimal: number, precision: number): string | undefined {
  const numerators: number[] = [0, 1];
  const denominators: number[] = [1, 0];
  const maxNumerator: number = getMaxNumerator(decimal);
  const acceptableError: number = Math.pow(10, -precision) / 2;
  let localDecimal: number = decimal;
  let calcDecimal: number;
  for (let i: number = 2; i < 1000; i++) {
    const floorDecimal: number = Math.floor(localDecimal);
    numerators[i] = floorDecimal * numerators[i - 1] + numerators[i - 2];
    if (Math.abs(numerators[i]) > maxNumerator) {
      return undefined;
    }
    denominators[i] = floorDecimal * denominators[i - 1] + denominators[i - 2];
    calcDecimal = numerators[i] / denominators[i];
    if (Math.abs(calcDecimal - decimal) < acceptableError || Number.isNaN(calcDecimal)) {
      return numerators[i].toString() + '/' + denominators[i].toString();
    }
    localDecimal = 1 / (localDecimal - floorDecimal);
  }
  return undefined;
}

function getMaxNumerator(fraction: number): number {
  let digits: number = fraction.toString().includes('.') ? Number(fraction.toString().replace('.', '')) : fraction;
  const numDigitsPastDecimal: number = digits.toString().length - (fraction === 0 ? 0 : fraction.toString().length);
  for (let i: number = numDigitsPastDecimal; i > 0 && digits % 2 === 0; i--) {
    digits /= 2;
  }
  for (let i: number = numDigitsPastDecimal; i > 0 && digits % 5 === 0; i--) {
    digits /= 5;
  }
  return Number(digits);
}

export const mapToOrderedBrandInfoAndApiAvailable =
  (ordered: BrandType[]) =>
  (fromApi: BrandInfo[]): BrandInfo[] => {
    return fromApi
      .filter((brand: BrandInfo) => ordered.includes(brand.name))
      .sort((a: BrandInfo, b: BrandInfo) => ordered.indexOf(a.name) - ordered.indexOf(b.name));
  };

export function zonedTimeToUtc(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

// copy from lib cleave.js
export function formatAsDecimal(inputValue: string, decimalMaxLength?: number): string {
  const numeralDelimiter: string = '.';

  const value: string = inputValue
    .replace(/[A-Za-z]/g, '')
    .replace(',', numeralDelimiter)
    .replace(numeralDelimiter, 'M')
    .replace(/[^\dM-]/g, '')
    .replace(/^\-/, 'N')
    .replace(/\-/g, '')
    .replace('M', numeralDelimiter);

  const splited: string[] = value.split(numeralDelimiter);
  const restPart: string = (splited[1] ?? []).slice(0, 2);
  const integerPart: string = decimalMaxLength ? splited[0].slice(0, decimalMaxLength) : splited[0];

  if (!value.includes(numeralDelimiter)) {
    return integerPart;
  }

  return `${integerPart}${numeralDelimiter}${restPart}`;
}

export function formatAsRound(inputValue: string): string {
  if (isStringAndNoEmpty(inputValue)) {
    return Math.round(Number(inputValue)).toString();
  }
  return inputValue;
}

export function formatAsFloatNumber(inputValue: number, toFixed: number = 2): string {
  const multipliedParsedValueAsString: string = inputValue.toString();
  const newValue: string =
    multipliedParsedValueAsString.length < 4
      ? multipliedParsedValueAsString
      : inputValue.toFixed(toFixed).endsWith('.00')
      ? multipliedParsedValueAsString.split('.')[0]
      : inputValue.toFixed(toFixed);

  return newValue;
}

/**
 * reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
 */
export function encodeRFC3986URIComponent(str: string): string {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c: string) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}
